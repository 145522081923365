import styled from 'styled-components';

export const PrizeDrawDetailWrapper = styled.div`
  overflow: hidden;
`;

export const PrizeDrawDetailHeaderWrapper = styled.div``;

export const PrizeDrawDetailLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;
  max-height: 100%;
  overflow: hidden;

  .loader-image {
    height: 64px;
    width: 64px;
  }

  .mfa-radio-button .selected-radio {
    margin: 0 !important;
  }
  .status-container {
    width: fit-content;
    border-radius: 18px;

    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
    }
  }

  .cancle-button-profile {
    border: 1px solid ${({ theme }) => theme.colors.zervioBlue};
    padding: 2px 16px;
    background: transparent;
    label {
      color: ${({ theme }) => theme.colors.zervioBlue};
      font-size: 12px;
      line-height: 20px;
    }
  }
  .cancle-button-email {
    border: 1px solid ${({ theme }) => theme.colors.zervioBlue};
    padding: 6px 40px;
    background: transparent;
    label {
      color: ${({ theme }) => theme.colors.zervioBlue};
      line-height: 20px;
      font-weight: 500;
    }
  }
  .email-mfa-save {
    padding: 6px 40px !important;
    width: 100% !important;
    label {
      line-height: 20px !important;
    }
  }

  .selected-radio {
    accent-color: ${({ theme }) => theme.colors.zervioBlue};
  }

  .mfa-form {
    display: flex;
    flex-direction: column;

    .otp-content {
      margin-top: 8px;
      gap: 8px;

      .otp-input {
        margin: 0px;

        .input-style {
          width: 32px;
          height: 32px;
          border: unset;
          text-align: center;
          background: #ffffff;
          // box-shadow: 5px 5px 30px rgb(5 49 73 / 10%);
          font-size: 16px;
          color: #053149;
          font-family: Poppins Regular;
          border: 1px solid #d0d5dd;
          border-radius: 8px;

          &:focus-visible {
            outline: 0px;
            border: 1px solid ${({ theme }) => theme.colors.zervioBlue};
          }

          &::-webkit-input-placeholder {
            opacity: 0.3;
            color: #053149;
          }
        }

        .error-style {
          border: 1px solid #ff5b5b;

          &:focus-visible {
            border: 1px solid #ff5b5b;
          }
        }
      }
    }

    .re-send-icon {
      path {
        fill: rgb(137, 39, 239);
      }
    }

    .otp-error {
      width: 340px;
      background: #ff406e;
      border-radius: 8px;
      padding: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .error-msg {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 50px;

      .error-icon {
        min-width: 12px;
        min-height: 12px;
        width: 12px;
        height: 12px;
        margin: 0px;
      }

      label {
        font-family: Poppins Medium;
        font-style: normal;
        font-size: 12px;
        color: #d93e38;
        margin-left: 5px;
      }
    }

    .error-message {
      display: flex;
      align-items: center;
      background: #ff406e;
      border-radius: 8px;
      padding: 4px 8px;

      .error-icon {
        path {
          stroke: #ffffff;
        }
      }
    }
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}

  // NOTE: Lifecycle class
  .active {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenPositiveText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenPositive};
    }
  }

  .inactive {
    background: ${({ theme }) => theme.colors.ZenGray2};
    span {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenDefault};
    }
  }

  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }

      .hover-edit-margin {
        margin-right: 0;
      }
    }
  }
  .edit-input {
    height: 40px !important;
  }

  .description-input {
    font-size: 14px;
  }

  .code-input {
    height: 24px;
    font-size: 14px;
  }
  .content-hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      margin-top: -4px;
      margin-bottom: -4px;

      .edit-icon {
        display: flex;
      }
      .role-edit-icon {
        display: flex;
      }

      .hover-margin {
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
      .hover-margin-bottom {
        margin-bottom: 4px;
      }
    }
  }

  .content-hover-edit-1 {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      padding-left: 8px;
      margin-right: -8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-top: -4px;
      margin-bottom: -4px;
      .edit-icon {
        display: flex;
      }
    }
  }
  .img-data-wrapper {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    .delete-btn-wrapper {
      height: 24px;
      width: 24px;
      background: #ffff;
      display: none;
    }
    &:hover {
      .delete-btn-wrapper {
        display: flex;
      }
    }
  }
  .img-container {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.Gray3};
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};

    .add-img-btn {
      display: none;
      height: 24px;
      width: 24px;
      background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }

    &:hover {
      background: #ffff;
      opacity: 1;

      .empty-img {
        display: none;
      }

      .add-img-btn {
        display: flex;
      }
    }
  }

  .empty-img-content-details {
    background: ${({ theme }) => theme.colors.zervioBlue};
    opacity: 0.3;
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};

    .add-img-btn {
      display: none;
      height: 24px;
      width: 24px;
      background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }

    &:hover {
      background: #ffff;
      opacity: 1;

      .empty-img {
        display: none;
      }

      .add-img-btn {
        display: flex;
      }
    }
  }
  .numeric-id {
    right: 16px;
    opacity: 0.3;
  }
`;

export const PrizeDrawFormWrapper = styled.div`
  .header-container {
    height: 64px;
  }
  .option-hover {
    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
      color: ${({ theme }) => theme.colors.zervioBlue};
      border-radius: 8px;
    }
  }
  .item-container {
    min-height: 66px;
    max-height: 66px;
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(220px, 1.5fr) minmax(140px, 150px) minmax(140px, 175px);

    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }

  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zervioBlue};
      fill: ${({ theme }) => theme.colors.zervioBlue};
    }
  }

  .input-search {
    .input {
      width: 180px;
    }
  }

  .status-wrapper {
    height: 28px;
    border-radius: 20px;
  }

  // status class
  .default {
    background: ${({ theme }) => theme.colors.ZenOrangeSecondary};
    color: ${({ theme }) => theme.colors.ZenOrangeText};
  }
  .custom {
    background: ${({ theme }) => theme.colors.customStatusBackground};
    color: ${({ theme }) => theme.colors.customStatusText};
  }
`;

export const PrizeDrawEntrantsWrapper = styled.div`
  .header-container {
    height: 64px;
  }

  .w-full {
    width: 100% !important;
  }

  .prize-draw-entrant-filter {
    left: -115px;
  }

  .infinite-scroll-component__outerdiv {
    height: 100%;
    .infinite-scroll-component {
      height: calc(100% + 1px) !important;
    }
  }

  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zervioBlue};
      fill: ${({ theme }) => theme.colors.zervioBlue};
    }
  }

  .content-header {
    display: grid;
    grid-template-columns:
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(135px, 135px) minmax(100px, 110px) minmax(100px, 1fr)
      minmax(95px, 95px) minmax(135px, 135px);
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .content-body {
    display: grid;
    grid-template-columns:
      minmax(120px, 1fr) minmax(120px, 1fr) minmax(135px, 135px) minmax(100px, 110px) minmax(100px, 1fr)
      minmax(95px, 95px) minmax(135px, 135px);

    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }

  .tooltip-content {
    padding: 6px 8px;
    border-radius: 8px;
  }
  .payment-status-tooltip {
    padding: 10px 8px;
  }
  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      .content-body,
      .content-header {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(115px, 115px) minmax(115px, 115px);
      }
      .input-search {
        .input {
          width: 180px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      .content-body,
      .content-header {
        grid-template-columns: 1fr 1fr 1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr minmax(180px, 180px);
      }
    }
  `}

  .status-container {
    width: fit-content;
    border-radius: 18px;
    .dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
    }
  }

  .count-box {
    background: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.LighterText};
    height: 24px;
    border-radius: 100px;
  }

  // NOTE: payment status
  .active {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenPositiveText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenPositive};
    }
  }

  .inactive {
    background: ${({ theme }) => theme.colors.ZenGray2};
    span {
      color: ${({ theme }) => theme.colors.ZenDefault};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenDefault};
    }
  }

  .canceled {
    background: ${({ theme }) => theme.colors.ZenRedSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenRed};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenRed};
    }
  }

  .unpaid {
    background: ${({ theme }) => theme.colors.ZenGray2Secondary};
    span {
      color: ${({ theme }) => theme.colors.RegularText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.RegularText};
    }
  }

  .pastdue {
    background: ${({ theme }) => theme.colors.ZenOrangeSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenOrangeText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenOrangeText};
    }
  }

  // NOTE: draw status
  .indraw {
    background: ${({ theme }) => theme.colors.customStatusBackground};
    span {
      color: ${({ theme }) => theme.colors.customStatusText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.customStatusText};
    }
  }

  .outofdraw {
    background: ${({ theme }) => theme.colors.ZenPinkSecondary2};
    span {
      color: ${({ theme }) => theme.colors.ZenPink2};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenPink2};
    }
  }

  //NOTE winner status
  .winner {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    span {
      color: ${({ theme }) => theme.colors.ZenPositiveText};
    }
    .dot {
      background: ${({ theme }) => theme.colors.ZenPositive};
    }
  }
  .entrant {
    background: ${({ theme }) => theme.colors.zervioBlueSecondary2};
    span {
      color: ${({ theme }) => theme.colors.zervioBlue};
    }
    .dot {
      background: ${({ theme }) => theme.colors.zervioBlue};
    }
  }
`;

export const PrizeDrawOrganizationssWrapper = styled.div`
  .header-container {
    height: 64px;
  }

  .provider-selector {
    width: 100% important;
    .organisations-border {
      height: auto;
      min-height: 40px;
      padding: 2px 8px;
    }
    .mc-options {
      position: absolute;
      top: 50px !important;
    }
  }

  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .content-body {
    display: grid;
    grid-template-columns: 1fr 1fr minmax(130px, 130px) minmax(115px, 115px) 1fr minmax(150px, 150px);
  }

  .update-action {
    top: 44px;
    z-index: 1;
  }

  .update-action-top {
    top: 63px;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      .content-body,
      .content-header {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(115px, 115px) minmax(115px, 115px);
      }
      .input-search {
        .input {
          width: 180px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      .content-body,
      .content-header {
        grid-template-columns: 1fr 1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr minmax(180px, 180px);
      }
    }
  `}
`;

export const PrizeDrawOrganizationItemWrapper = styled.div`
  .list-container {
    min-height: 72px;
    padding: 8px 0px;
    display: grid;
    grid-template-columns: minmax(200px, 230px) minmax(200px, 1fr) minmax(200px, 300px) minmax(150px, 200px);
  }

  .sub-item-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .update-action {
    top: 82px;
    right: 20px;
  }

  .update-action-top {
    top: 100px;
  }

  .list-container-3-col {
    min-height: 72px;
    padding: 8px 0px;
    display: grid;
    grid-template-columns: minmax(230px, 250px) 1fr 1fr;
  }

  .bdr-right {
    border-right: 1px solid ${({ theme }) => theme.colors.LightGrey};
  }

  .status-container {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    border-radius: 18px;
    padding: 0px 8px;
    label {
      color: ${({ theme }) => theme.colors.ZenPositive};
    }
  }
  .subitem-status-container {
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    border-radius: 18px;
    padding: 0px 8px;
    label {
      color: ${({ theme }) => theme.colors.zervioBlue};
    }
  }
`;

export const PrizeDrawDetailRightWrapper = styled.div`
  border-radius: 16px;
`;

export const PrizeDrawSettingsWrapper = styled.div`
  .hover-edit {
    .edit-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      .edit-icon {
        display: flex;
      }
    }
  }

  .header-detail-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 100px;
    max-height: 24px;
  }

  .prize-input,
  .age-input {
    width: 64px;
    height: 32px;
    border-radius: 12px;
    font-size: 14px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  .age-input-value {
    width: 96px;
    padding-right: 40px;
  }

  .age-input-type {
    right: 12px;
  }

  .prize-input {
    width: 96px;
    height: 24px;
    border-radius: 8px;
  }

  .entry-input {
    width: 64px;
    height: 24px;
    border-radius: 8px;
  }

  .currency-icon {
    left: 12px;
  }

  .selector-dropdown {
    .mc-select {
      .organisations-border {
        height: 24px;
        width: 120px;
        border-radius: 8px;
        padding: 8px;
      }
      .mc-options {
        top: 26px;
        z-index: 11;
      }
    }
  }

  .state-dropdown {
    .mc-select {
      .organisations-border {
        width: 180px;
        height: 32px;
        border-radius: 12px;
      }
      .dropdown-open {
        border-color: ${({ theme }) => theme.colors.zervioBlue};
      }
      .mc-options {
        top: 40px;
        z-index: 11;
      }
    }
  }

  .icon {
    padding: 4px;
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary_O3};
    border-radius: 8px;
    width: fit-content;
  }

  .delete-icon {
    padding: 4px;
    background: ${({ theme }) => theme.colors.ZenNegativeSecondary_O3};
    width: fit-content;
    border-radius: 8px;
  }

  .prize-entry-container {
    padding: 2px 8px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .error-container {
    top: -20px;
    background: #ffffff;
    box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
    border-radius: 8px;
    width: fit-content;
    right: 0;

    .border-left-content {
      display: flex;
      margin-right: 4px;
      border-radius: 8px 0 0 8px;
      width: 8px;
      background: ${({ theme }) => theme.colors.ZenNegative};
    }
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export const PrizeDrawClientsItemWrapper = styled.div`
  .list-container {
    min-height: 72px;
    padding: 8px 0px;
    display: grid;
    grid-template-columns: minmax(200px, 230px) minmax(200px, 1fr) minmax(200px, 300px) minmax(150px, 200px);
  }

  .update-action {
    top: 82px;
    right: 20px;
  }

  .update-action-top {
    top: 100px;
  }

  .list-container-3-col {
    min-height: 72px;
    padding: 8px 0px;
    display: grid;
    grid-template-columns: minmax(230px, 250px) minmax(230px, 250px) 1fr;
  }

  .bdr-right {
    border-right: 1px solid ${({ theme }) => theme.colors.LightGrey};
  }

  .status-container {
    background: ${({ theme }) => theme.colors.ZenPinkSecondary};
    border-radius: 18px;
    padding: 0px 8px;
    label {
      color: ${({ theme }) => theme.colors.zenPink};
    }
  }
  .subitem-status-container {
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    border-radius: 18px;
    padding: 0px 8px;
    label {
      color: ${({ theme }) => theme.colors.zervioBlue};
    }
  }
`;

export const PrizeDrawClientsWrapper = styled.div`
  .header-container {
    height: 64px;
  }

  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
    }
  }

  .provider-selector {
    width: 100% important;
    .organisations-border {
      height: auto;
      min-height: 40px;
      padding: 2px 8px;
    }
    .mc-options {
      position: absolute;
      top: 50px !important;
    }
  }

  .placeholder {
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
  }

  .content-body {
    display: grid;
    grid-template-columns: 1fr 1fr minmax(130px, 130px) minmax(115px, 115px) 1fr minmax(150px, 150px);
  }

  .update-action {
    top: 50px;
    z-index: 1;
  }

  .update-action-top {
    top: 63px;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      .content-body,
      .content-header {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(115px, 115px) minmax(115px, 115px);
      }
      .input-search {
        .input {
          width: 180px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      .content-body,
      .content-header {
        grid-template-columns: 1fr 1fr minmax(150px, 1fr) minmax(150px, 1fr) 1fr minmax(180px, 180px);
      }
    }
  `}
`;

export const PrizeDrawDetailEntrantLeftWrapper = styled.div`
  height: fit-content;
  min-width: 350px;
  max-width: 350px;
  border-radius: 16px;

  .address-fields {
    row-gap: 8px;

    .line-1,
    .line-2,
    .city,
    .state,
    .zip-code {
      row-gap: 4px;
    }

    .input,
    .mc-select {
      height: 40px;
    }

    .address-input {
      .async-select__control {
        height: 40px;
        min-height: 40px;
      }
    }

    .selected-name {
      font-size: 16px;
    }

    .error-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative};

      .organisations-border {
        border-color: ${({ theme }) => theme.colors.ZenNegative};
      }
    }
  }

  .title-input {
    .organisations-border {
      width: 90px;
      height: 40px;

      .selected-name {
        font-size: 16px;
      }
    }
  }

  .edit-input {
    height: 40px;
  }

  .update-action-container {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      min-width: 320px;
      max-width: 320px;
      transition: width 400ms linear;

    }

    ${up(breakpoints.xl)} {
      min-width: 400px;
      max-width: 400px;
      transition: width 400ms linear;
    }
  `}
  .line-21 {
    line-height: 21px;
  }

  .line-16 {
    line-height: 16px;
  }

  .address-status {
    padding: 2px 8px;
    margin-left: 8px;
    border-radius: 18px;
  }

  .Shipping {
    background: ${({ theme }) => theme.colors.ZenOrange_05};
    color: ${({ theme }) => theme.colors.ZenOrangeText};
  }

  .Billing {
    background: ${({ theme }) => theme.colors.ZenPositive_05};
    color: ${({ theme }) => theme.colors.ZenPositive};
  }

  .code-input {
    height: 24px;
    font-size: 14px;
  }

  .img-container {
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
  }
  .empty-img-content {
    background: ${({ theme }) => theme.colors.zervioBlue};
    opacity: 0.3;
  }

  .width {
    width: 240px;
  }
  .content-hover-edit {
    .copy-icon {
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 10px rgba(9, 19, 53, 0.1);
      border-radius: 8px;
      margin-left: -8px;
      padding-left: 8px;
      padding-right: 8px;
      margin-right: -8px;
      .copy-icon {
        display: flex;
      }
    }
  }

  .update-action-container {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const PrizeDrawEntrantDetailsRightWarpper = styled.div``;

export const PrizeDrawEntrantOverviewWrapper = styled.div``;

export const PrizeDrawEntrantNotesWrapper = styled.div`
  .add-note-input {
    min-height: 40px;
    max-height: 40px;
  }

  .infinite-scroll-component__outerdiv {
    display: flex;
    flex: 1 1;

    .infinite-scroll-component {
      flex: 1 1;
    }
  }
`;

export const PrizeDrawNotesGroupWrapper = styled.div`
  .notes-item {
    .vertical-line {
      border-left: 1px solid ${({ theme }) => theme.colors.divider};
      min-height: calc(100% - 16px);
    }

    .notes-item-text {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .edit-icon {
        display: none;
      }
      &:hover {
        background: #ffff;
        box-shadow: 0px 4px 10px 0px rgba(22, 25, 44, 0.1);
        border-radius: 16px;
        .edit-icon {
          display: flex;
        }
      }
    }

    .not-edit-notes-item-text {
      background: ${({ theme }) => theme.colors.backgroundColor};
      .edit-icon {
        display: none;
      }
    }

    .note-item-textbox {
      font-size: 12px;
    }
  }
`;

export const PrizeDrawEntrantEntryReferralWrapper = styled.div`
  .download-container {
    border: 1px solid ${({ theme }) => theme.colors.divider};
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .download-button {
    border: 1px solid ${({ theme }) => theme.colors.zervioBlueSecondary2};
    background: ${({ theme }) => theme.colors.zervioBlueSecondary2};
    height: 32px;
    padding: 6px 8px;
  }

  .fundraising-grid {
    display: grid;
    grid-template-columns: 160px 1fr 2fr;
  }
`;

export const PrizeDrawEntrantLogsWrapper = styled.div`
  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }
  .filter-card-open {
    right: 136px;
  }
  .prize-draw-entrant-filter {
    left: -118px;
  }
  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zervioBlue};
      fill: ${({ theme }) => theme.colors.zervioBlue};
    }
  }
`;

export const ActivityLogGroupWraper = styled.div``;

export const ActivityLogItemWrapper = styled.div`
  .vertical-line {
    border-left: 1px solid ${({ theme }) => theme.colors.divider};
    min-height: calc(100% - 16px);
  }
  .note-created-wrapper {
    .note-title {
      background: ${({ theme }) => theme.colors.backgroundColor};
      min-height: 32px;
    }
  }
`;

export const PrizeDrawLogsWrapper = styled.div`
  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }
  .filter-card-open {
    right: 136px;
  }
  .prize-draw-entrant-filter {
    left: -118px;
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zervioBlue};
      fill: ${({ theme }) => theme.colors.zervioBlue};
    }
  }
`;
