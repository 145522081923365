import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { importFormVersion } from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';

const FormImportAction = () => {
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);
  const { form, onSuccess = () => {} } = modal.content;
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const onConfirmAction = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(importFormVersion({ formId: form.id, body: JSON.parse(data) }))
      .then(() => {
        dispatch(addToast({ error: false, text: `From has been imported`, id: uuid() }));
        onSuccess();
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: `Form has not been imported`, id: uuid() }));
      })
      .finally(() => setModal(initModal));
  };

  return (
    <CommonPopup
      popupTitle={'Import version'}
      confirmButtonProps={{
        label: 'Import',
        className: 'primary',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <textarea
        className={`textarea w-full description-input`}
        onChange={e => {
          setData(e.target.value);
        }}
        placeholder="Enter form here"
        rows={4}
        value={data || ''}
      />
    </CommonPopup>
  );
};

export default FormImportAction;
