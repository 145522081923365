import React from 'react';
import styled from 'styled-components';

const CheckboxRounded = ({ onChange = () => {}, checked = false, showRequiredError }) => {
  return (
    <CheckboxRoundedWrapper className="flex items-center">
      <input
        className={`cursor checkbox ${checked && 'checked'} ${showRequiredError && !checked && 'required-error'}`}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
    </CheckboxRoundedWrapper>
  );
};

const CheckboxRoundedWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
  }

  .checked {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.ZenGraySecondary};
    position: relative;
    outline: 4px solid ${({ theme }) => theme.colors.zervioBlue};
    outline-offset: -8px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export default CheckboxRounded;
