import React, { useContext } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { OrganisationContext } from '../../../context/organisationContext';
import { FormDetailsRightWrapper } from '../../../styles/components/formDetails/form-details.styled';
import FormLog from './form-log';
import ShareOptions from './share-options';
import FormOrganizations from './form-organizations';

const formDetailsRoutes = [
  {
    id: 1,
    path: '/share-options',
    Component: ShareOptions,
    permission: ['FORM_SHAREOPTIONS_VIEW'],
  },
  {
    id: 2,
    path: '/form-log',
    Component: FormLog,
    permission: ['FORMS_VERSIONS_VIEW'],
  },
  {
    id: 3,
    path: '/organizations',
    Component: FormOrganizations,
    permission: ['FORMS_VERSIONS_VIEW'],
  },
];

function FormDetailsRight() {
  const { hasMultiplePermission } = useContext(OrganisationContext);
  const { id, form_id } = useParams();

  const getRoute = () => {
    const formIdFromUrl = form_id ? form_id : id;
    const currentPath = form_id ? `/prize-draws/all/${id}/form-details/${formIdFromUrl}` : `/forms/${formIdFromUrl}`;

    const route = formDetailsRoutes.find(path => hasMultiplePermission(path.permission));
    if (route) {
      return `${currentPath}${route.path}`;
    }
    return null;
  };

  return (
    <FormDetailsRightWrapper className="flex divider-border flex-1 radius-4">
      <Routes>
        {formDetailsRoutes
          .filter(path => hasMultiplePermission(path.permission))
          .map(path => (
            <Route
              key={path.id}
              element={<path.Component {...path.params} path={path.path} {...path.actions} />}
              path={path.path}
            />
          ))}
        {getRoute() && <Route element={<Navigate replace to={getRoute()} />} path="*" />}
      </Routes>
    </FormDetailsRightWrapper>
  );
}

export default FormDetailsRight;
