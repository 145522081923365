import React, { useEffect, useMemo, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { OrganisationContext } from '../../context/organisationContext';
import history from '../../routes/history';

import Sidebar from '../../components/sidebar/sidebar';

import { ReactComponent as AllPrizeDrawSelectedIcon } from '../../assets/images/sidebar/all-prize-draw-selected.svg';
import { ReactComponent as AllPrizeDrawsIcon } from '../../assets/images/sidebar/all-prize-draws.svg';
import { ReactComponent as FeesSelectedIcon } from '../../assets/images/sidebar/billing-groups-selected.svg';
import { ReactComponent as FeesGroupsIcon } from '../../assets/images/sidebar/billing-groups.svg';
import { ReactComponent as BillingsIcon } from '../../assets/images/sidebar/billing.svg';
import { ReactComponent as DashboardIcon } from '../../assets/images/sidebar/dashboard.svg';
import { ReactComponent as FinancialsSelectedIcon } from '../../assets/images/sidebar/financials-selected.svg';
import { ReactComponent as FinancialsIcon } from '../../assets/images/sidebar/financials.svg';
import { ReactComponent as FormBuilderSelectedIcon } from '../../assets/images/sidebar/form-builder-selected.svg';
import { ReactComponent as FormBuilderIcon } from '../../assets/images/sidebar/form-builder.svg';
import { ReactComponent as GroupsIcon } from '../../assets/images/sidebar/groups.svg';
import { ReactComponent as PlatformsIcon } from '../../assets/images/sidebar/platforms.svg';
import { ReactComponent as PrizeDrawsIcon } from '../../assets/images/sidebar/prize-draws.svg';
import { ReactComponent as SettingSelectedIcon } from '../../assets/images/sidebar/settings-selected.svg';
import { ReactComponent as SettingIcon } from '../../assets/images/sidebar/settings.svg';

import { ReactComponent as HomeIcon } from '../../assets/images/menu/home.svg';
import { ReactComponent as AutomationlogSelected } from '../../assets/images/sidebar/automationlog-selected.svg';
import { ReactComponent as Automationlog } from '../../assets/images/sidebar/automationlog.svg';
import { ReactComponent as AutomationsSelectedIcon } from '../../assets/images/sidebar/automations-selected.svg';
import { ReactComponent as AutomationsIcon } from '../../assets/images/sidebar/automations.svg';
import { ReactComponent as IntegrationsSelectedIcon } from '../../assets/images/sidebar/integrations-selected.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/images/sidebar/integrations.svg';

import { useDispatch, useSelector } from 'react-redux';
import { getUsersPermissions } from '../../helpers/utils';
import Modal from '../../popup';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';
import { ROUTE_PATHS } from './route-paths';

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user } = useSelector(state => state.user);

  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [expanded, setExpanded] = useState(true);

  const permissions = getUsersPermissions();

  const hasPermission = permission => {
    return permissions.includes(permission);
  };

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const items = useMemo(
    () => [
      {
        id: 1,
        icon: FormBuilderIcon,
        iconSelected: FormBuilderSelectedIcon,
        text: 'Forms',
        redirect: '/forms',
        selected: location.pathname.startsWith('/forms'),
        subitems: [],
        hasPermission: hasMultiplePermission(['FORMS_VIEW']),
      },
      // {
      //   id: 7,
      //   icon: HomeIcon,
      //   iconSelected: HomeIcon,
      //   text: 'Home',
      //   redirect: '/home',
      //   selected: location.pathname === '/home',
      //   subitems: [],
      //   hasPermission: hasMultiplePermission(['HOMEPAGE_VIEW', 'ENTRANTS_VIEW', 'HOMEPAGE_LOG_VIEW']),
      // },
      // {
      //   id: 1,
      //   icon: DashboardIcon,
      //   iconSelected: DashboardIcon,
      //   text: 'Dashboard',
      //   redirect: '/dashboard',
      //   selected: location.pathname === '/dashboard',
      //   subitems: [],
      //   hasPermission: hasPermission('ENTRANT_STATS_VIEW'),
      // },
      // {
      //   id: 2,
      //   icon: PrizeDrawsIcon,
      //   iconSelected: PrizeDrawsIcon,
      //   text: 'Prize Draws',
      //   redirect: '/prize-draws',
      //   selected: location.pathname === '/prize-draws',
      //   hasPermission: hasMultiplePermission(['PRIZEDRAWS_VIEW', 'PRIZEDRAWS_MANAGE', 'FORMS_VIEW']),
      //   subitems: [
      //     {
      //       id: 0,
      //       icon: AllPrizeDrawsIcon,
      //       iconSelected: AllPrizeDrawSelectedIcon,
      //       text: 'All Prize Draws',
      //       redirect: '/prize-draws/all',
      //       selected: location.pathname.startsWith('/prize-draws/all'),
      //       hasPermission: hasMultiplePermission(['PRIZEDRAWS_VIEW', 'PRIZEDRAWS_MANAGE']),
      //     },
      //     {
      //       id: 1,
      //       icon: FormBuilderIcon,
      //       iconSelected: FormBuilderSelectedIcon,
      //       text: 'Forms',
      //       redirect: '/prize-draws/forms',
      //       selected: location.pathname.startsWith('/prize-draws/forms'),
      //       hasPermission: hasMultiplePermission(['FORMS_VIEW_LIST']),
      //     },
      //   ],
      // },
      // {
      //   id: 3,
      //   icon: BillingsIcon,
      //   iconSelected: PrizeDrawsIcon,
      //   text: 'Billings',
      //   redirect: '/billings',
      //   selected: location.pathname === '/billings',
      //   hasPermission: hasMultiplePermission(['FEES_VIEW', 'FEES_MANAGE', 'TRANSACTION_FEES_VIEW']),
      //   subitems: [
      //     {
      //       id: 0,
      //       icon: FeesGroupsIcon,
      //       iconSelected: FeesSelectedIcon,
      //       text: 'Fees',
      //       redirect: '/billings/fees',
      //       selected: location.pathname.startsWith('/billings/fees'),
      //       hasPermission: hasMultiplePermission(['FEES_VIEW', 'FEES_MANAGE']),
      //     },
      //     {
      //       id: 1,
      //       icon: FinancialsIcon,
      //       iconSelected: FinancialsSelectedIcon,
      //       text: 'Overview',
      //       redirect: '/billings/overview',
      //       selected: location.pathname.startsWith('/billings/overview'),
      //       hasPermission: hasMultiplePermission(['TRANSACTION_FEES_VIEW']),
      //     },
      //   ],
      // },
      // {
      //   id: 4,
      //   icon: GroupsIcon,
      //   iconSelected: GroupsIcon,
      //   text: 'Groups',
      //   redirect: '/groups',
      //   selected: location.pathname === '/groups',
      //   subitems: [],
      //   setStrokeOfIcon: true,
      //   hasPermission: hasMultiplePermission(['ORGANIZATION_CATEGORIES_VIEW', 'ORGANIZATION_CATEGORIES_MANAGE']),
      // },
      // {
      //   id: 5,
      //   icon: PlatformsIcon,
      //   iconSelected: PlatformsIcon,
      //   text: 'Platforms',
      //   redirect: '/platforms',
      //   selected: location.pathname === '/platforms',
      //   hasPermission: hasMultiplePermission([
      //     'INTEGRATIONS_VIEW',
      //     'INTEGRATIONS_MANAGE',
      //     'AUTOMATIONS_VIEW',
      //     'AUTOMATIONS_MANAGE',
      //     'AUTOMATION_LOGS_VIEW',
      //   ]),
      //   subitems: [
      //     {
      //       id: 0,
      //       icon: IntegrationsIcon,
      //       iconSelected: IntegrationsSelectedIcon,
      //       text: 'Integrations',
      //       redirect: '/platforms/integrations',
      //       selected: location.pathname.startsWith('/platforms/integrations'),
      //       setStrokeOfIcon: true,
      //       hasPermission: hasMultiplePermission(['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE']),
      //     },
      //     {
      //       id: 1,
      //       icon: AutomationsIcon,
      //       iconSelected: AutomationsSelectedIcon,
      //       text: 'Automations',
      //       redirect: '/platforms/automations',
      //       selected: location.pathname.startsWith('/platforms/automations'),
      //       setStrokeOfIcon: true,
      //       hasPermission: hasMultiplePermission(['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE']),
      //     },
      //     {
      //       id: 2,
      //       icon: Automationlog,
      //       iconSelected: AutomationlogSelected,
      //       text: 'Automation log',
      //       redirect: '/platforms/automation-log',
      //       selected: location.pathname.startsWith('/platforms/automation-log'),
      //       setStrokeOfIcon: false,
      //       hasPermission: hasMultiplePermission(['AUTOMATION_LOGS_VIEW']),
      //     },
      //   ],
      // },
      // {
      //   id: 6,
      //   icon: SettingIcon,
      //   iconSelected: SettingSelectedIcon,
      //   text: 'Settings',
      //   redirect: '/settings',
      //   selected: location.pathname === '/settings',
      //   subitems: [],
      //   setStrokeOfIcon: true,
      //   hasPermission: hasMultiplePermission(['SETTINGS_VIEW']),
      // },
    ],
    [location.pathname],
  );

  const [menuItems, setMenuItems] = useState(
    items,
    // .filter(item => item.hasPermission)
    // .map(item => ({ ...item, subitems: item.subitems ? item.subitems.filter(si => si.hasPermission) : [] })),
  );

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
          navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    if (selectedPath)
      setMenuItems(
        menuItems.map(menuItem =>
          selectedPath.startsWith(menuItem.redirect)
            ? {
                ...menuItem,
                open: menuItem.subitems.length > 0,
                selected: true,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: selectedPath.startsWith(subitem.redirect),
                })),
              }
            : {
                ...menuItem,
                selected: false,
                open: false,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: false,
                })),
              },
        ),
      );
  }, [selectedPath]);

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  return (
    <MainWrapper>
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
        }}>
        <Sidebar
          menuItems={menuItems}
          onItemClick={onItemClick}
          selectedPath={selectedPath}
          user={user}
          setExpanded={setExpanded}
          expanded={expanded}
        />
        <div className="main">
          <div className="content">
            <Routes>
              {ROUTE_PATHS.filter(path => hasMultiplePermission(path.permission)).map(path => (
                <Route
                  key={path.id}
                  element={<path.Component {...path.params} path={path.path} {...path.actions} expanded={expanded} />}
                  path={path.path}
                />
              ))}
              <Route
                element={
                  <Navigate
                    replace
                    to={
                      ROUTE_PATHS.find(path => hasMultiplePermission(path.permission))
                        ? ROUTE_PATHS.find(path => hasMultiplePermission(path.permission)).path
                        : '/profile'
                    }
                  />
                }
                path="*"
              />
            </Routes>
          </div>
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
