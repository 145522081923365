import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FormDetailsLeftWrapper } from '../../../styles/components/formDetails/form-details.styled';
import Status from '../../common/status';

const FormDetailsLeft = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { formDetails } = useSelector(state => state.forms);
  const { name, description, prizedraw, form_type, prizedraw_client } = formDetails || {};

  return (
    <FormDetailsLeftWrapper className="flex-column divider-border">
      <div className="flex items-center divider-bottom px-6 py-4">
        <label className="flex-1 font-20 medium-text fw-500">{t('FORM_DETAILS')}</label>
        <div className="flex items-center justify-start">{/* <Menu /> */}</div>
      </div>
      <div className="py-6 pl-6 pr-4 flex-column flex-1 overflow-scroll">
        <div className="flex items-center">
          <div className="flex-column flex-1">
            <span className="regular-text grey-text font-12 mb-1">{t('NAME')}</span>
            <div className="flex items-center justify-start mr-10">
              <label className="semibold-text font-16 one-line w-full one-line">{name}</label>
            </div>
          </div>
        </div>
        <div className="flex-column mt-6">
          <span className="regular-text grey-text font-12 mb-1">{t('DESCRIPTION')}</span>
          <div className="flex items-center justify-start wrap mr-10">
            <p className=" regular-text w-full break-word">{description}</p>
          </div>
        </div>
        <div className="flex mt-6">
          <div className="flex-1 flex-column">
            <span className="regular-text grey-text font-12 mb-1">{t('LABEL')}</span>
            {form_type?.name && (
              <div className="flex items-center justify-start wrap">
                <Status
                  dotColor={form_type?.is_custom ? theme.colors.ZenBlue : theme.colors.ZenOrange}
                  withDot
                  withDottedBorder
                  status={'FORM_TYPE_LABEL'}
                  statusText={form_type?.name}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </FormDetailsLeftWrapper>
  );
};

export default FormDetailsLeft;
