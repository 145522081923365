import FormDetails from '../form-details';
import Forms from '../forms';
import Profile from '../profile/profile';
export const ROUTE_PATHS = [
  {
    id: 3,
    path: '/forms',
    Component: Forms,
    permission: ['FORMS_VIEW'],
  },
  {
    id: 7,
    path: '/profile',
    Component: Profile,
  },
  {
    id: 17,
    path: '/forms/:id/*',
    Component: FormDetails,
    permission: ['FORMS_VIEW'],
  },
];
