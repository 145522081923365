import { createSlice } from '@reduxjs/toolkit';
import { capitalize, formatText } from '../../helpers/utils';
import api from '../../store/services';
import originalTokenapi from '../../store/services/original-token-api';
import { inviteUser } from './userManagementSlice';

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    groups: [],
    groupTags: [],
    accountList: {},
  },
  reducers: {
    setGroups: (state, { payload }) => {
      state.groups = payload;
    },
    setGroupDetail: (state, { payload }) => {
      state.groupDetails = payload;
    },
    setAccountList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.accountList?.content, ...payload?.data?.content] };
        state.accountList = mergeData;
      } else {
        state.accountList = payload.data;
      }
    },
    setGroupTag: (state, { payload }) => {
      state.groupTags = payload;
    },
    setOrganizationUserRole: (state, { payload }) => {
      state.organizationUserRoles = payload;
    },
    setAccountDetails: (state, { payload }) => {
      state.accountDetails = payload;
    },
    setOrganizationPrizedraw: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.organizationPrizedraw?.content, ...payload?.data?.content],
        };
        state.organizationPrizedraw = mergeData;
      } else {
        state.organizationPrizedraw = payload.data;
      }
    },
  },
});

export const getGroups = payload => async dispatch => {
  try {
    const { data } = await api.get(`/organization_categories`, { params: payload?.params });
    dispatch(setGroups(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGroupDetail = payload => async dispatch => {
  try {
    const { data } = await api.get(`/organization_categories/${payload.id}`);
    dispatch(setGroupDetail(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountList = payload => async dispatch => {
  try {
    const { params, organizationID, merge } = payload;
    const { data } = await api.get(`/organizations/${organizationID}/network`, {
      params: params,
    });
    dispatch(setAccountList({ data: data, merge: merge }));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createGroupCategory = payload => async dispatch => {
  try {
    const { data } = await api.post(`/organization_categories`, payload.request);
    dispatch(getGroups());
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/addresses`, { params: payload?.params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressDetails = payload => async dispatch => {
  try {
    const { data } = await api.get(`/addresses/${payload.id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createOrganization = payload => async dispatch => {
  try {
    const { data } = await api.post(`/organizations`, payload.request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContacts = payload => async dispatch => {
  try {
    const { organizationId, request } = payload;
    const { data } = await api.post(`/organizations/${organizationId}/contact_details`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createLocation = payload => async dispatch => {
  try {
    const { organizationId, request } = payload;
    const { data } = await api.post(`/organizations/${organizationId}/locations`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createUser = payload => async dispatch => {
  try {
    const { request, isInviteUserAPI, organizationId } = payload;
    const { data } = await api.post(`/organizations/${organizationId}/users`, request);
    if (isInviteUserAPI) {
      await dispatch(inviteUser({ organization_id: organizationId, user: data }));
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateGroupDetails = payload => async dispatch => {
  try {
    const { request, id } = payload;
    const { data } = await api.put(`/organization_categories/${id}`, request);
    dispatch(getGroupDetail({ id: id }));
    return Promise.resolve(data);
  } catch (error) {}
};

export const getGroupTag = payload => async dispatch => {
  try {
    const { data } = await api.get(`/category_tags`);
    const updatedTag = data.map(item => {
      return { ...item, name: capitalize(formatText(item.tag, ' ')), value: item.id };
    });
    dispatch(setGroupTag(updatedTag));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationUserRole = payload => async dispatch => {
  try {
    const { organizationID, params } = payload;
    const { data } = await api.get(`/organizations/${organizationID}/roles`, { params: params });
    dispatch(setOrganizationUserRole(data));
    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountDetails = payload => async dispatch => {
  try {
    const { organizationId } = payload;
    const { data } = await api.get(`/organizations/${organizationId}`);
    dispatch(setAccountDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationPrizedraw = payload => async dispatch => {
  try {
    const { organizationId, merge, params } = payload;
    const { data } = await api.get(`/organizations/${organizationId}/prizedraws`, {
      params: params,
    });
    dispatch(setOrganizationPrizedraw({ data: data, merge: merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateContactDetails = payload => async dispatch => {
  try {
    const { organizationId, contactId, params } = payload;
    const { data } = await api.put(`/organizations/${organizationId}/contact_details/${contactId}`, {
      ...params,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteContactDetails = payload => async dispatch => {
  try {
    const { organizationId, contactId } = payload;
    const { data } = await api.delete(`/organizations/${organizationId}/contact_details/${contactId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const onAccessAccount = payload => async dispatch => {
  try {
    const { organizationId } = payload;
    const { data } = await api.post(`/organizations/${organizationId}/masquerade`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const onAccessAccountUsingOrignalToken = payload => async dispatch => {
  try {
    const { organizationId } = payload;
    const { data } = await originalTokenapi.post(`/organizations/${organizationId}/masquerade`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateLocation = payload => async dispatch => {
  try {
    const { organizationId, locationId, request } = payload;
    const { data } = await api.put(`/organizations/${organizationId}/locations/${locationId}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateName = payload => async dispatch => {
  try {
    const { organizationId, params } = payload;
    const { data } = await api.put(`/organizations/${organizationId}`, {
      ...params,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePrimaryUser = payload => async dispatch => {
  try {
    const { organizationId, request } = payload;
    const { data } = await api.put(`/organizations/${organizationId}/owners`, request);
    dispatch(getAccountDetails({ organizationId: organizationId }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeGroup = payload => async dispatch => {
  try {
    const { organizationCategoryId } = payload;
    const { data } = await api.delete(`/organization_categories/${organizationCategoryId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeAccountData = payload => async dispatch => {
  try {
    const { organization_id } = payload;
    const { data } = await api.delete(`/organizations/${organization_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {
  setGroups,
  setGroupDetail,
  setAccountList,
  setGroupTag,
  setOrganizationUserRole,
  setAccountDetails,
  setOrganizationPrizedraw,
} = groupsSlice.actions;
export default groupsSlice.reducer;
