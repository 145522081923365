import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../../../assets/images/burger.svg';
import Loading from '../../../../assets/images/loading.svg';
import { ReactComponent as UpIcon } from '../../../../assets/images/sorting.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import useDebounce from '../../../../helpers/useDebounceHook';
import { formatText } from '../../../../helpers/utils';
import { getFormVersions } from '../../../../store/features/formsSlice';
import { FormLogWrapper } from '../../../../styles/components/formDetails/form-details.styled';
import InputSearch from '../../../common/input-search';
import Loader from '../../../common/loader';
import Menu from '../../../common/menu';
import NoDataComponent from '../../../common/no-data-component';
import NoResultComponent from '../../../common/no-result-component';
import Status from '../../../common/status';
import FormDetailsHeader from '../form-details-header';
import Button from '../../../Elements/button/button';

const FormLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(null);
  const location = useLocation();
  const isAll = location.pathname.includes('all');
  const { id, form_id } = useParams();
  const { t } = useTranslation();
  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { formVersions, formDetails } = useSelector(state => state.forms);

  const [sortedBy, setSortedBy] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const getFormVersionsData = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getFormVersions({
        id: form_id ? form_id : id,
        params: { page: page, size: 20, search: debouncedSearch, sort_by: sortedBy, order_by: orderBy },
        merge,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getFormVersionsData(0, false, debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    getFormVersionsData(0, false, debouncedSearch, false);
  }, [sortedBy, orderBy]);

  const fetchMoreData = () => {
    getFormVersionsData(pageRef.current + 1, true, debouncedSearch);
  };

  const refreshFormVersionsList = () => {
    getFormVersionsData(0, false, debouncedSearch);
  };

  const formHeader = [
    {
      name: t('VERSION'),
      value: 'VERSION',
      sort_by: 'version_no',
    },
    {
      name: t('CREATED'),
      value: 'CREATED',
      sort_by: 'created_on',
    },
    {
      name: t('PUBLISHED'),
      value: 'PUBLISHED',
      sort_by: 'published_on',
    },
    {
      name: t('RETIRED'),
      value: 'RETIRED',
      sort_by: 'archived_on',
    },
    {
      name: t('STATUS'),
      value: 'STATUS',
      ignoreRightBorder: true,
    },
  ];

  const getVersionStatus = version => {
    const { created_on, published_on, archived_on } = version;
    if (created_on && published_on && archived_on) {
      return 'RETIRED';
    }
    if (created_on && published_on) {
      return 'PUBLISHED';
    }
    if (created_on) {
      return 'DRAFT';
    }
    return '';
  };

  const getPublishedVersion = () => {
    return formVersions?.content?.find(v => getVersionStatus(v) === 'PUBLISHED');
  };

  const onDuplicateFormVersion = version => {
    setModal({
      type: 'form-version-action',
      content: {
        form: formDetails,
        formVersion: version,
        isDuplicate: true,
        title: t('VERSION_POPUP_TITLE', { type: 'duplicate' }),
        top: true,
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  const onExportFormVersion = version => {
    setModal({
      type: 'form-export',
      content: {
        form: formDetails,
        formVersion: version,
        title: t('VERSION_POPUP_TITLE', { type: 'export' }),
        top: true,
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  const onRetireFormVersion = version => {
    setModal({
      type: 'form-version-action',
      content: {
        form: formDetails,
        formVersion: version,
        isRetire: true,
        title: t('VERSION_POPUP_TITLE', { type: 'retire' }),
        top: true,
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  const onPublishVersion = version => {
    setModal({
      type: 'publish-form-version',
      content: {
        form: formDetails,
        formVersionToPublish: version,
        affectedFormVersion: getPublishedVersion(),
        title: t('PUBLISH_VERSION_POPUP_TITLE', { type: 'publish' }),
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  const onRePublishVersion = version => {
    setModal({
      type: 'publish-form-version',
      content: {
        form: formDetails,
        formVersionToPublish: version,
        affectedFormVersion: getPublishedVersion(),
        isRePublish: true,
        title: t('PUBLISH_VERSION_POPUP_TITLE', { type: 'republish' }),
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  const getVersionMenuItem = version => {
    const versionStatus = getVersionStatus(version);
    if (versionStatus === 'RETIRED') {
      return [{ name: t('REPUBLISH'), topSeparator: true, onClick: () => onRePublishVersion(version) }];
    }
    if (versionStatus === 'PUBLISHED') {
      return [{ name: t('RETIRE'), topSeparator: true, onClick: () => onRetireFormVersion(version) }];
    }
    if (versionStatus === 'DRAFT') {
      return [{ name: t('PUBLISH'), topSeparator: true, onClick: () => onPublishVersion(version) }];
    }
    return [];
  };

  const goToFormVersionDetails = version => {
    navigate(
      isAll
        ? `/form-details/${formDetails?.id}/form-version/${version.id}`
        : `/forms/${formDetails.id}/form-version/${version.id}`,
    );
  };

  const handleSorting = fieldName => {
    if (!sortedBy) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    } else if (sortedBy === fieldName) {
      if (!orderBy) {
        setOrderBy('DESC');
      } else if (orderBy === 'DESC') {
        setOrderBy('ASC');
      } else if (orderBy === 'ASC') {
        setOrderBy('');
        setSortedBy('');
      }
    } else if (sortedBy !== fieldName) {
      setSortedBy(fieldName);
      setOrderBy('DESC');
    }
  };

  const onShareOption = () => {
    navigate('../share-options');
  };

  const onFormOrganizations = () => {
    navigate('../organizations');
  };

  const menuItemsWithPermission = [
    { name: 'Form log', onClick: () => {}, key: 'FORMS_VERSIONS_VIEW' },
    { name: 'Organizations', onClick: () => onFormOrganizations(), key: 'FORMS_VERSIONS_VIEW' },
    { name: 'Share options', onClick: () => onShareOption(), key: 'FORM_SHAREOPTIONS_VIEW' },
  ];

  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));

  const onImport = () => {
    setModal({
      type: 'form-import',
      content: {
        form: formDetails,
        isDuplicate: true,
        title: t('VERSION_POPUP_TITLE', { type: 'import' }),
        top: true,
        onSuccess: () => refreshFormVersionsList(),
      },
    });
  };

  return (
    <FormLogWrapper className="flex-column items-start justify-start w-full h-full">
      <FormDetailsHeader
        title={t('FORM_LOG')}
        actionContent={
          <>
            <div className="items-center justify-between w-full mr-2">
              <div className="flex items-center justify-end flex-1">
                <div className="mr-4 no-select">
                  <Button
                    {...{
                      label: 'Import',
                      size: 'small',
                      borderRadius: '8px',
                      className: 'primary',
                      width: '100px',
                    }}
                    onClick={onImport}
                  />
                </div>
                <InputSearch
                  placeholder={'Search'}
                  value={searchText}
                  onChange={setSearchText}
                  className="input-search"
                  inputType="number"
                />
              </div>
              <Menu menuClassName="ml-4" Icon={HamburgerIcon} menuList={[...filteredMenuItems]} />
            </div>
          </>
        }
      />
      {loading ? (
        <Loader height={32} width={32} />
      ) : formVersions?.content?.length > 0 ? (
        <>
          <div className="py-2 divider-bottom w-full header-container">
            {formHeader.map(({ name, ignoreRightBorder, value, sort_by }) => (
              <div
                key={value}
                className={`flex items-center justify-center header-item-container ${sort_by && 'cursor'} ${
                  !ignoreRightBorder && 'divider-right'
                }`}
                onClick={() => handleSorting(sort_by)}>
                <span className="medium-text lighter-text font-12">{name}</span>
                {sort_by && sortedBy === sort_by && orderBy && (
                  <UpIcon
                    className={`flex items-center justify-center ${orderBy === 'DESC' ? 'rotate-180' : ''}`}
                    height={16}
                    width={16}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="flex-column flex-1 overflow-scroll w-full" id="scrollableDiv">
            <InfiniteScroll
              className="flex-column flex-1"
              dataLength={formVersions?.content.length}
              hasMore={!formVersions?.last}
              height={48}
              loader={
                <div className="item flex items-center justify-center">
                  <img alt="loading" height="32px" src={Loading} />
                </div>
              }
              next={fetchMoreData}
              scrollableTarget="scrollableDiv">
              {formVersions?.content?.map(version => (
                <div
                  key={version.id}
                  className="flex items-center justify-start w-full divider-bottom item-container cursor"
                  onClick={() => goToFormVersionDetails(version)}>
                  <div className={`flex items-center justify-center header-item-container divider-right`}>
                    <span className="regular-text">V{version.version_no}</span>
                  </div>
                  <div className={`flex items-center justify-center header-item-container divider-right`}>
                    <span className={`regular-text ${!version.created_on && 'grey-text'}`}>
                      {version.created_on
                        ? moment.unix(version.created_on).format('MMM DD, YYYY')
                        : formatText(t('NO_DATA'))}
                    </span>
                  </div>
                  <div className={`flex items-center justify-center header-item-container divider-right`}>
                    <span className={`regular-text ${!version.published_on && 'grey-text'}`}>
                      {version.published_on
                        ? moment.unix(version.published_on).format('MMM DD, YYYY')
                        : formatText(t('NO_DATA'))}
                    </span>
                  </div>
                  <div className={`flex items-center justify-center header-item-container divider-right`}>
                    <span className={`regular-text ${!version.archived_on && 'grey-text'}`}>
                      {version.archived_on
                        ? moment.unix(version.archived_on).format('MMM DD, YYYY')
                        : formatText(t('NO_DATA'))}
                    </span>
                  </div>
                  <div className={`flex items-center justify-center header-item-container`}>
                    <Status status={getVersionStatus(version)} withDot />
                  </div>
                  <div className="flex items-center justify-between">
                    <Menu
                      menuList={[
                        { name: t('DETAILS'), onClick: () => goToFormVersionDetails(version) },
                        { name: t('DUPLICATE'), onClick: () => onDuplicateFormVersion(version) },
                        { name: t('EXPORT'), onClick: () => onExportFormVersion(version) },

                        ...getVersionMenuItem(version),
                      ]}
                    />
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </FormLogWrapper>
  );
};

export default FormLog;
