import { combineReducers, configureStore } from '@reduxjs/toolkit';
import formsSlice from './features/formsSlice';
import groupsSlice from './features/groupsSlice';
import profileSlice from './features/profileSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import userManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';

const combinedReducer = combineReducers({
  reload: reloadSlice,
  toast: toastSlice,
  user: userSlice,
  groups: groupsSlice,

  usersManagement: userManagementSlice,
  forms: formsSlice,
  profile: profileSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/setUserLogout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_STAGE === 'development',
});
