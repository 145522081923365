import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HamburgerIcon } from '../../../../assets/images/burger.svg';
import { OrganisationContext } from '../../../../context/organisationContext';
import useDebounce from '../../../../helpers/useDebounceHook';
import {
  getFormOrganizations,
  addFormOrganization,
  deleteFormOrganization,
} from '../../../../store/features/formsSlice';
import { FormLogWrapper } from '../../../../styles/components/formDetails/form-details.styled';
import Loader from '../../../common/loader';
import Menu from '../../../common/menu';
import NoDataComponent from '../../../common/no-data-component';
import NoResultComponent from '../../../common/no-result-component';
import FormDetailsHeader from '../form-details-header';
import Button from '../../../Elements/button/button';
import DropDown from '../../../McDropdown';
import { getAccountList } from '../../../../store/features/groupsSlice';
import { getItemFromLocalStorage } from '../../../../helpers/localstorage';

const FormOrganizations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageRef = useRef(null);
  const location = useLocation();
  const { id, form_id } = useParams();
  const { t } = useTranslation();
  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { formOrganizations } = useSelector(state => state.forms);
  const { accountList } = useSelector(state => state.groups);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showAdd, setShowAdd] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const debouncedSearch = useDebounce(searchText, 500);

  const localUser = getItemFromLocalStorage('user');
  const orgId = localUser.organizations?.[0]?.id;

  useEffect(() => {
    dispatch(getFormOrganizations({ id: id }));
  }, []);

  const formHeader = [
    {
      name: t('NAME'),
      value: 'NAME',
    },
  ];

  const onShareOption = () => {
    navigate('../share-options');
  };

  const onFormLog = () => {
    navigate('../form-log');
  };

  const menuItemsWithPermission = [
    { name: 'Form log', onClick: () => onFormLog(), key: 'FORMS_VERSIONS_VIEW' },
    { name: 'Organizations', onClick: () => {}, key: 'FORMS_VERSIONS_VIEW' },
    { name: 'Share options', onClick: () => onShareOption(), key: 'FORM_SHAREOPTIONS_VIEW' },
  ];

  const filteredMenuItems = menuItemsWithPermission.filter(permission => hasPermission(permission.key));

  useEffect(() => {
    fetchOrganizations(0, false);
  }, []);

  const fetchOrganizations = (page, merge) => {
    dispatch(getAccountList({ organizationID: orgId, params: { page: page, merge: merge }, url: '' }));
  };

  const onAdd = async () => {
    await dispatch(addFormOrganization({ id: id, body: { organization: { id: selectedOrganization.id } } }));
    dispatch(getFormOrganizations({ id: id }));
    setSelectedOrganization(null);
    setShowAdd(false);
  };

  const deleteOrganization = async org => {
    await dispatch(deleteFormOrganization({ id: id, orgId: org.id }));
    dispatch(getFormOrganizations({ id: id }));
  };

  return (
    <FormLogWrapper className="flex-column items-start justify-start w-full h-full">
      <FormDetailsHeader
        title={t('FORM_ORGANIZATIONS')}
        actionContent={
          <>
            <div className="items-center justify-between w-full mr-2">
              <div className="flex items-center justify-end flex-1">
                <div className="mr-4 no-select">
                  <Button
                    {...{
                      label: 'Add',
                      size: 'small',
                      borderRadius: '8px',
                      className: 'primary',
                      width: '100px',
                    }}
                    onClick={() => setShowAdd(true)}
                  />
                </div>
              </div>
              <Menu menuClassName="ml-4" Icon={HamburgerIcon} menuList={[...filteredMenuItems]} />
            </div>
          </>
        }
      />
      {loading ? (
        <Loader height={32} width={32} />
      ) : formOrganizations?.length > 0 ? (
        <>
          <div className="py-2 divider-bottom w-full organization-header-container">
            {formHeader.map(({ name, ignoreRightBorder, value, sort_by }) => (
              <div key={value} className={`flex items-center header-item-container ${sort_by && 'cursor'}`}>
                <span className="medium-text lighter-text font-12 ml-4">{name}</span>
              </div>
            ))}
          </div>
          <div className="flex-column overflow-scroll w-full">
            {formOrganizations?.map(org => (
              <div
                key={org.id}
                className="flex items-center justify-start w-full divider-bottom organization-item-container cursor py-2">
                <div className={`flex items-center header-item-container `}>
                  <span className="regular-text ml-4">V{org.name}</span>
                </div>
                <div className={`flex items-center justify-center header-item-container`}></div>
                <div className="flex items-center justify-between">
                  <Menu menuList={[{ name: t('DELETE'), onClick: () => deleteOrganization(org) }]} />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
      {showAdd && (
        <div className="flex w-full items-center justify-between border-bottom">
          <div className="flex flex-1 pxy-4">
            <DropDown
              options={accountList?.content}
              selected={selectedOrganization}
              setSelected={setSelectedOrganization}
              placeholder={'Select organization'}
              hasMore={accountList?.hasMore}
              isPaged={true}
              fetchData={() => fetchOrganizations(pageRef.current + 1, true)}
            />
          </div>
          <div className="flex items-center">
            <div style={{ width: '70px' }}>
              <Button
                {...{
                  label: 'Cancel',
                  size: 'medium',
                  borderRadius: '8px',
                  className: 'primary',
                  width: '100px',
                }}
                onClick={() => setShowAdd(false)}
              />
            </div>
            <div className="ml-2 mr-4" style={{ width: '70px' }}>
              <Button
                {...{
                  label: 'Save',
                  size: 'medium',
                  borderRadius: '8px',
                  className: 'primary',
                  width: '100px',
                }}
                onClick={() => onAdd()}
              />
            </div>
          </div>
        </div>
      )}
    </FormLogWrapper>
  );
};

export default FormOrganizations;
