import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DesktopSelectedIcon } from '../../assets/images/monitor-selected.svg';
import { ReactComponent as DesktopIcon } from '../../assets/images/monitor.svg';
import { ReactComponent as MobileSelectedIcon } from '../../assets/images/smartphone-selected.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/smartphone.svg';
import { ReactComponent as TabletSelectedIcon } from '../../assets/images/tablet-selected.svg';
import { ReactComponent as TabletIcon } from '../../assets/images/tablet.svg';
import Logo from '../../assets/images/zervio-whole.svg';
import Button from '../../components/Elements/button/button';
import Switch from '../../components/common/switch';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { HeaderPanelWrapper } from './edit-form-version.styled';

const HeaderPanel = ({ setPreviewForm, previewForm, selectedDevice, setSelectedDevice }) => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { t } = useTranslation();
  return (
    <HeaderPanelWrapper className="flex">
      <div className="logo-container border-right pl-4 py-6">
        <img src={Logo} alt="zen-logo" />
      </div>
      <div className="flex-1 flex items-center px-10 py-6 border-bottom">
        <div className="flex-1 flex">
          <label className="regular-text mr-4">{t('PREVIEW_FORM')}</label>
          <Switch enabled={previewForm} onClick={() => setPreviewForm(!previewForm)} />
        </div>
        {previewForm && (
          <Fragment>
            <div
              className={`pxy-3 cursor radius-2 border flex items-center justify-center device-selector-wrapper mr-6 ${
                selectedDevice === 'MONITOR' && 'selected-device'
              }`}
              onClick={() => setSelectedDevice('MONITOR')}>
              {selectedDevice === 'MONITOR' ? <DesktopSelectedIcon /> : <DesktopIcon />}
            </div>
            <div
              className={`pxy-3 cursor radius-2 border flex items-center justify-center device-selector-wrapper mr-6 ${
                selectedDevice === 'TABLET' && 'selected-device'
              }`}
              onClick={() => setSelectedDevice('TABLET')}>
              {selectedDevice === 'TABLET' ? <TabletSelectedIcon /> : <TabletIcon />}
            </div>
            <div
              className={`pxy-3 cursor radius-2 border flex items-center justify-center device-selector-wrapper mr-6 ${
                selectedDevice === 'MOBILE' && 'selected-device'
              }`}
              onClick={() => setSelectedDevice('MOBILE')}>
              {selectedDevice === 'MOBILE' ? <MobileSelectedIcon /> : <MobileIcon />}
            </div>
          </Fragment>
        )}
        <Button
          size="average"
          label="Done"
          className="primary specified-width"
          width="130px"
          borderRadius="12px"
          onClick={() => setModal(initModal)}
        />
      </div>
    </HeaderPanelWrapper>
  );
};

export default HeaderPanel;
