import Axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import HidePassword from '../../assets/images/hide-password.svg';

import ShowPassword from '../../assets/images/show-password.svg';
import AuthContainer from '../../components/common/auth-container';
import Button from '../../components/Elements/button/button';
import { emailRegEx } from '../../helpers/utils';
import { setUser } from '../../store/features/userSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showNetworkError, setShowNetworkError] = useState(false);
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState('');
  const [emailError, setEmailError] = useState(false);

  const isEmailValid = email => {
    return emailRegEx.test(email);
  };

  const onLogin = async event => {
    if (active) {
      if (!isEmailValid(email)) {
        setEmailError(true);
        return;
      }
      try {
        event.preventDefault();
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/authentication`, {
          identity: email,
          password: password,
        });
        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
        navigate('/properties');
      } catch (e) {
        const { code } = e || {};
        if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
          setShowNetworkError(true);
        } else {
          setShowError(true);
        }
      }
    }
  };

  const changePassword = e => {
    setPassword(e.target.value);
    setShowError(false);
  };

  const changeEmail = email => {
    setEmail(email);
    setShowError(false);
    setEmailError(!isEmailValid(email));
  };

  useEffect(() => {
    setActive(email && password && email.length > 2 && password.length > 2);
  }, [email, password]);

  return (
    <AuthContainer>
      <LoginWrapper>
        <div className="flex-column items-center login-form w-full gap-10">
          {/* Heading */}
          <div className="flex flex-column items-center gap-2">
            <label className="inter-700-text natural-900-text font-28">{t('WELCOME_BACK')}</label>
            <label className="inter-400-text natural-500-text">{t('LOG_IN_TO_YOUR_ACCOUNT')}</label>
          </div>
          {/* end */}
          {(loginStatus === 'FAILURE' || loginStatus === 'INACTIVE') && (
            <div className="error-message-alert items-center justify-between flex mt-10">
              <label className="inter-600-text error-text py-3">
                {loginStatus === 'INACTIVE' ? t('ACCOUNT_DEACTIVATE_DETAIL_TEXT') : t('INCORRECT_EMAIL_PASSWORD')}
              </label>
              <CloseIcon />
            </div>
          )}
          {showError && (
            <div className="flex error-message-alert items-center justify-between mt-10 w-full">
              <label className="inter-400-text error-text py-3 w-full">{t('INCORRECT_EMAIL_PASSWORD')}</label>
              <CloseIcon />
            </div>
          )}
          {showNetworkError && (
            <div className="flex error-message-alert items-center justify-between mt-10 w-full">
              <label className="inter-400-text error-text py-3 w-full">{t('OFFLINE_OR_POOR_WIFI')}</label>
              <CloseIcon />
            </div>
          )}
          <div className="w-full flex gap-5 form flex-column items-center">
            <div className="w-full flex-column gap-5px">
              <label className="inter-500-text natural-900-text">{t('EMAIL_ADDRESS')}</label>
              <input
                placeholder={t('ENTER_EMAIL_ADDRESS')}
                onChange={e => changeEmail(e.target.value)}
                className={classNames('input radius-2', emailError && 'error-border')}
              />
              {emailError && (
                <label className="inter-400-text error-text block mt-2">{t('INVALID_EMAIL_FORMAT')}</label>
              )}
            </div>
            <div className="w-full flex-column relative gap-5px">
              <label className="inter-500-text natural-900-text mb-1">{t('PASSWORD')}</label>
              <div className="relative flex w-full">
                <input
                  className={`input password-input radius-2 w-full ${showError && 'error-border'}`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('ENTER_PASSWORD')}
                  onChange={e => changePassword(e)}
                />
                <img
                  className="show-password"
                  src={showPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
            </div>
          </div>
          {/* Bottom Button  */}
          <div className="w-full flex flex-column gap-5 items-center">
            <Button
              className={classNames('primary', !(active || showError || emailError) && 'disabled')}
              size="large"
              label={t('LOGIN')}
              onClick={onLogin}
            />
          </div>
          {/* end */}
        </div>
      </LoginWrapper>
    </AuthContainer>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 360px;

  .form {
    .password-input {
      padding-right: 48px;
    }
    .show-password {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 12px;
      cursor: pointer;
    }

    a {
      text-decoration: none;
      align-self: center;
    }
  }
`;

export default Login;
