import React, { useContext, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { ColorPicker } from '../common/color-picker';
import Button from '../../../components/Elements/button/button';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import { uploadMedia } from '../../../store/services/media';
import Slider from '../../../components/Elements/slider/slider';

export const PanelImageSetting = ({ panel, panelConfig, setPanelConfig }) => {
  const inputRef = useRef();
  const [uploadingImage, setUploadingImage] = useState(false);

  const onImagePanelUpload = async (file, panel) => {
    if (file) {
      try {
        setUploadingImage(true);
        const imageData = await uploadMedia(file);
        setPanelConfig(
          panelConfig.map(pc =>
            pc.id === panel.id
              ? {
                  ...pc,
                  imageId: imageData?.media_external_id,
                  image: {
                    url: imageData.url,
                    id: imageData?.media_external_id,
                    media_external_id: imageData?.media_external_id,
                  },
                }
              : {
                  ...pc,
                  image: pc.image
                    ? { id: pc?.image?.media_external_id, media_external_id: pc?.image?.media_external_id }
                    : null,
                },
          ),
        );
        setUploadingImage(false);
        inputRef.current.value = '';
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image' }));
        setUploadingImage(false);
        inputRef.current.value = '';
      }
    }
  };

  return (
    <div key={panel.id}>
      {/* <label className="flex regular-text font-12 flex-1 mb-2">{panel?.name}</label> */}
      <div className="flex-column justify-center mb-4">
        <label className="flex regular-text font-12 flex-1 mb-2">Background image</label>
        {panel?.image?.url ? (
          <div className="flex one-line">
            <div className="flex-column flex-1 one-line">
              {panel?.image?.name && <label className="regular-text one-line">{panel?.image?.name}</label>}
            </div>
            <div
              className="pxy-1 radius-2 flex delete-widget h-fit-content cursor"
              onClick={() =>
                setPanelConfig(
                  panelConfig.map(pc =>
                    pc.id === panel.id
                      ? { ...panel, image: null }
                      : {
                          ...pc,
                          image: pc.image
                            ? { id: pc?.image?.media_external_id, media_external_id: pc?.image?.media_external_id }
                            : null,
                        },
                  ),
                )
              }>
              <DeleteIcon width={14} height={14} />
            </div>
          </div>
        ) : (
          <div className="flex items-center mt-2 col-gap-4">
            <input
              className="display-none"
              type="file"
              ref={inputRef}
              onChange={e => {
                const file = e.target.files[0];
                onImagePanelUpload(file, panel);
              }}
            />
            <Button
              icon={uploadingImage ? <LoadingIcon height={14} /> : <Fragment />}
              label={'Upload image'}
              size="medium"
              borderRadius="12px"
              className="primary-white flex-1"
              onClick={() => !uploadingImage && inputRef.current.click()}
            />
            <label className="flex-1 regular-text font-12">SVG, PNG, JPG or GIF (max. 000x000px)</label>
          </div>
        )}
      </div>
    </div>
  );
};
