import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormDetailsComponent from '../../components/form-details';
import PageHeader from '../../components/page-header';
import {
  getFormDetails,
  setFormDetails,
  setFormVersionDetails,
  setFormVersions,
} from '../../store/features/formsSlice';
import { FormDetailsWrapper } from '../../styles/pages/automation-details.styled';

const FormDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, form_id } = useParams();
  const { formDetails, formVersionDetails } = useSelector(state => state.forms);

  useEffect(() => {
    dispatch(getFormDetails({ id: form_id ? form_id : id }));

    return () => {
      dispatch(setFormDetails({}));
      dispatch(setFormVersions({}));
      dispatch(setFormVersionDetails({}));
    };
  }, []);

  const isFormVersionUI = location.pathname.includes('form-version');

  const onFormNameClick = () => {
    if (isFormVersionUI) {
      navigate(formDetails ? `/forms/${formDetails?.id}/form-log` : `/forms`);
    }
  };

  const names = [
    { text: 'Forms', path: '/forms' },
    { text: formDetails?.name, onClick: () => onFormNameClick() },
    ...(isFormVersionUI ? [{ text: `V${formVersionDetails?.version_no}`, onClick: () => {} }] : []),
  ];

  return form_id ? (
    <FormDetailsComponent formId={form_id ? true : false} />
  ) : (
    <FormDetailsWrapper className="flex-column flex-1">
      <PageHeader names={names} />
      <FormDetailsComponent />
    </FormDetailsWrapper>
  );
};

export default FormDetails;
