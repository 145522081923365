import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import Loading from '../../assets/images/loading.svg';
import { FormBuilderProvider } from '../../context/FormBuilderContext';
import { OrganisationContext } from '../../context/organisationContext';
import api from '../../store/services/index';
import { EditFormVersionWrapper } from './edit-form-version.styled';
import FormPreview from './form-preview';
import HeaderPanel from './header-panel';
import LeftPanel from './left-panel';
import MiddlePanel from './middle-panel';
import InsideModal from './popup';
import RightPanel from './right-panel';
import { components } from 'react-select';

const EditFormVersion = () => {
  const { setModal, modal } = useContext(OrganisationContext);
  const { formVersionId, prizedraw_client } = modal.content;
  // const formVersionId = '646d8546-5fb4-45d8-bc39-8e33179aab6b';
  const [previewForm, setPreviewForm] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('MONITOR');

  const [insideModal, setInsideModal] = useState(null);
  const [loadingFormData, setLoadingFormData] = useState(true);
  const [fonts, setFonts] = useState([]);
  const [lists, setLists] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [panelConfig, setPanelConfig] = useState([]);
  const [zoom, setZoom] = useState(100);
  const [widgetGroupList, setWidgetGroupList] = useState([]);
  const [flatWidgetList, setFlatWidgetList] = useState([]);
  const [formAttributes, setFormAttributes] = useState({
    color: {
      button: '#8927EF',
      default_component: '#FFFFFF',
      page: '#FFFFFF',
    },
    font: {
      name: 'Poppins',
      style: 'REGULAR',
      size: 20,
      color: '#16192C',
    },
    progress_display: 'TOP',
    field_accent_color: '#8927EF',
    confirmation_panel: null,
    panel_width: 720,
    background: {
      image_id: '',
      color: '#E2E7EF',
    },
  });
  const [panelWidgets, setPanelWidgets] = useState([]);
  const [selectedPage, setSelectedPage] = useState({});
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [showEditPage, setShowEditPage] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedIntegrationInComponent, setSelectedIntegrationInComponent] = useState(null);
  const rowRef = useRef(1);
  const widgetUpdateTimeout = useRef(null);
  const componentUpdateTimeout = useRef(null);

  const getFormAttributes = async () => {
    try {
      const { data } = await api.get(`/versions/${formVersionId}/form_attributes`);
      setFormAttributes(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getFonts = async () => {
    try {
      const { data } = await api.get(`/fonts`);
      setFonts(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getLists = async () => {
    try {
      const { data } = await api.get(`/lists`);
      setLists(data.map(d => ({ ...d, label: d.name, value: d.id })));
    } catch (e) {
      console.log(e);
    }
  };

  const getListDetail = async id => {
    try {
      const { data } = await api.get(`/lists/${id}`);
      setLists(lists?.map(d => (d.id === id ? { ...data, label: data.name, value: data.id } : d)));
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const updateListChoiceConfig = async (id, choices) => {
    try {
      const { data } = await api.put(`/lists/${id}/choices`, choices);
      setLists(lists?.map(d => (d.id === id ? { ...d, choices: data } : d)));
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getIntegrations = async () => {
    try {
      const { data } = await api.get(`/integrations`, {
        params: { organization_id: prizedraw_client?.organization?.id, action: 'PRODUCT_LIST' },
      });
      setIntegrations(data.map(d => ({ ...d, label: d.name, value: d.id })));
    } catch (e) {
      console.log(e);
    }
  };

  const getIntegrationDetails = async id => {
    try {
      const { data } = await api.get(`/integrations/${id}`);
      setIntegrations(integrations?.map(d => (d.id === id ? { ...data, label: data.name, value: data.id } : d)));
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getPanels = async () => {
    try {
      const { data } = await api.get(`/versions/${formVersionId}/panels`);
      const updatedData = data.map(d => ({ ...d, key: d.id })).sort((a, b) => a.priority - b.priority);
      setPanelConfig(updatedData);
      setSelectedPage(updatedData.find(d => d.id === selectedPage?.id) || updatedData[0]);
    } catch (e) {
      console.log(e);
    }
  };

  const getWidgetList = async () => {
    try {
      const { data } = await api.get('/widgets');
      setWidgetGroupList(data);
      setFlatWidgetList(data.map(group => group.widgets).flat());
    } catch (e) {
      console.log(e);
    }
  };

  const getIntegrationIdFromProductComponent = panelWidgets => {
    const widgetComponents = panelWidgets
      .map(p => p.widgets)
      .filter(x => x)
      .flat()
      .map(w => w.components)
      .filter(x => x)
      .flat();
    const productComponents = widgetComponents.filter(c => c.component_type === 'PRODUCT');
    const productComponent =
      productComponents.find(c => c.products?.length > 0 || c.integration?.id) || productComponents?.[0] || null;
    setSelectedIntegrationInComponent(productComponent);
  };

  const getPanelWidgetList = async () => {
    try {
      const { data } = await api.get(`/versions/${formVersionId}/panel_widgets`);
      const sortedPanelWidgets = data.map(d => ({ ...d, widgets: d.widgets.sort((a, b) => a.priority - b.priority) }));
      getIntegrationIdFromProductComponent(sortedPanelWidgets);
      setPanelWidgets(sortedPanelWidgets);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoadingFormData(true);
      await getFormAttributes();
      await getPanels();
      await getWidgetList();
      await getPanelWidgetList();
      getFonts();
      getLists();
      getIntegrations();
      setLoadingFormData(false);
    };

    getData();
  }, []);

  const updateFormAttributes = updatedFormAttributes => {
    setFormAttributes(updatedFormAttributes);
    api.put(`/versions/${formVersionId}/form_attributes`, updatedFormAttributes);
  };

  const updatePanelConfigData = async config => {
    const request = config.map(c => ({
      ...c,
      image: c.image?.media_external_id ? { id: c.image?.media_external_id } : null,
    }));
    const { data } = await api.put(`/versions/${formVersionId}/panels`, request);
    return data.map(d => ({ ...d, key: d.id })).sort((a, b) => a.priority - b.priority);
  };

  const updatePanelConfig = async updatedPanelConfig => {
    const configWithPriority = updatedPanelConfig.map((config, index) => ({
      ...config,
      priority: index + 1,
      panel_color: formAttributes.color.page,
    }));
    const updatedData = await updatePanelConfigData(configWithPriority);
    const newPanelWidgets = updatedData.reduce((acc, curr) => {
      const panelWidgetAvailable = panelWidgets.find(p => p.id === curr.id);
      if (!panelWidgetAvailable) {
        acc.push({ id: curr.id, key: curr.id, widgets: [] });
      } else {
        acc.push(panelWidgetAvailable);
      }
      return acc;
    }, []);
    if (updatedData.length === 1) setSelectedPage(updatedData[0]);
    else setSelectedPage(updatedData.find(p => p.id === selectedPage.id));
    setPanelConfig(updatedData);
    setPanelWidgets(newPanelWidgets);
    return updatedData;
  };

  const updatePanelPosition = updatedPanelConfig => {
    const configWithPriority = updatedPanelConfig.map((config, index) => ({ ...config, priority: index + 1 }));
    setPanelConfig(configWithPriority);
    const request = configWithPriority.map(c => ({
      ...c,
      image: c.image?.media_external_id ? { id: c.image?.media_external_id } : null,
    }));
    api.put(`/versions/${formVersionId}/panels`, request);
  };

  const onPageColorChange = updatedFormAttributes => {
    const updatedPanelConfig = panelConfig.map(panel => ({ ...panel, panel_color: updatedFormAttributes.color.page }));
    setFormAttributes(updatedFormAttributes);
    setPanelConfig(updatedPanelConfig);
    api.put(`/versions/${formVersionId}/form_attributes`, updatedFormAttributes);
    api.put(`/versions/${formVersionId}/panels`, updatedPanelConfig);
  };

  const onComponentColorChange = updatedFormAttributes => {
    setFormAttributes(updatedFormAttributes);
    api.put(`/versions/${formVersionId}/form_attributes`, updatedFormAttributes);
  };

  const getWidgetListRequestWithoutProducts = widgetList => {
    return widgetList?.map(widget => ({
      ...widget,
      components: widget.components?.map(component =>
        component.component_type === 'PAYMENT' ? { ...component, products: [] } : { ...component },
      ),
    }));
  };

  const updatePanelWidget = async (widgetList, panelIdForWidget = null) => {
    const panelId = panelIdForWidget ? panelIdForWidget : selectedPage.key;
    const widgetListRequest = getWidgetListRequestWithoutProducts(widgetList);
    const { data } = await api.put(`/panels/${panelId}/widgets`, widgetListRequest);
    setPanelWidgets(panelWidgets.map(panel => (panel.id === panelIdForWidget ? { ...panel, widgets: data } : panel)));
    return data;
  };

  const updatePanels = async panelWidgets => {
    await api.put(`/versions/${formVersionId}/panel_widgets`, panelWidgets);
  };

  const onAddWidget = async (widget, destinationIndex, panelIdForWidget) => {
    const { id, ...restWidget } = widget;
    const componentColor = formAttributes.color.default_component;
    const newWidget = {
      ...restWidget,
      key: uuid(),
      width: widget.width || 200,
      height: widget.height || 60,
      x: 0,
      y: 0,
      components: [
        ...restWidget.components.map(({ id, ...restComponent }) => ({
          ...restComponent,
          component_color: componentColor,
          span_columns: 1,
          key: uuid(),
          width: (widget.width || 200) / restWidget.components.length - 20,
          height: (widget.height || 60) / restWidget.components.length - 20,
          x: 10,
          y: 10,
        })),
      ],
      row: rowRef.current,
      isNew: true,
    };
    rowRef.current = rowRef.current + 1;
    const panelWidgetList = panelWidgets.find(p => p.id === panelIdForWidget)?.widgets || [];
    const newPanelWidgets = [...panelWidgetList];
    newPanelWidgets.splice(destinationIndex, 0, newWidget);
    const panelWidgetsWithPriority = newPanelWidgets.map((w, index) => ({ ...w, priority: index + 1 }));
    setPanelWidgets(
      panelWidgets.map(panel =>
        panel.id === panelIdForWidget ? { ...panel, widgets: panelWidgetsWithPriority } : panel,
      ),
    );
    updateSelectedWidget({ ...newWidget, panelId: panelIdForWidget });
    const data = await updatePanelWidget(panelWidgetsWithPriority, panelIdForWidget);
    const widgetData = data?.[destinationIndex] || {};
    updateSelectedWidget({ ...newWidget, ...widgetData, panelId: panelIdForWidget });
  };

  const onUpdateWidgetPosition = (sourceIndex, destinationIndex, panelIdForWidget) => {
    const panelWidgetList = panelWidgets.find(p => p.id === panelIdForWidget)?.widgets;
    const items = Array.from(panelWidgetList);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    const panelWidgetsWithPriority = items.map((w, index) => ({ ...w, priority: index + 1 }));

    setPanelWidgets(
      panelWidgets.map(panel =>
        panel.id === panelIdForWidget ? { ...panel, widgets: panelWidgetsWithPriority } : panel,
      ),
    );
    updatePanelWidget(panelWidgetsWithPriority, panelIdForWidget);
  };

  const onUpdateWidgetPanels = async (sourceIndex, destinationIndex, containerId, sourceContainerId) => {
    const sourcePanelWidgetList = panelWidgets.find(p => p.id === sourceContainerId)?.widgets;
    const destinationPanelWidgetList = panelWidgets.find(p => p.id === containerId)?.widgets;
    const sourceItems = Array.from(sourcePanelWidgetList);
    const destinationItems = Array.from(destinationPanelWidgetList);
    const [reorderedItem] = sourceItems.splice(sourceIndex, 1);

    const { id, ...restWidget } = reorderedItem;
    const newWidget = {
      ...restWidget,
      key: uuid(),
      components: [
        ...restWidget.components.map(({ id, ...restComponent }) => ({
          ...restComponent,
          key: uuid(),
        })),
      ],
      isNew: true,
    };

    destinationItems.splice(destinationIndex, 0, newWidget);
    const sourcePanelWidgetsWithPriority = sourceItems.map((w, index) => ({ ...w, priority: index + 1 }));
    const destinationPanelWidgetsWithPriority = destinationItems.map((w, index) => ({ ...w, priority: index + 1 }));

    const panels = panelConfig.map(panel =>
      panel.id === containerId
        ? { ...panel, widgets: destinationPanelWidgetsWithPriority }
        : panel.id === sourceContainerId
        ? { ...panel, widgets: sourcePanelWidgetsWithPriority }
        : panel,
    );

    const panelWidgetList = panelWidgets.map(panel =>
      panel.id === containerId
        ? { ...panel, widgets: destinationPanelWidgetsWithPriority }
        : panel.id === sourceContainerId
        ? { ...panel, widgets: sourcePanelWidgetsWithPriority }
        : panel,
    );
    setPanelWidgets(panelWidgetList);
    updatePanels(panels);
    updateSelectedWidget(null);
  };

  const handleOnDragEnd = result => {
    console.log(result);
    const { destination, draggableId, source } = result;
    if (!destination) {
      return;
    }
    const { droppableId, index: destinationIndex } = destination;
    const { droppableId: sourceDroppableId, index: sourceIndex } = source;

    const [droppableContainer, containerId] = droppableId.split(':');
    const [sourceContainer, sourceContainerId] = sourceDroppableId.split(':');

    if (droppableContainer === 'middle-panel') {
      if (sourceContainer === 'widget-list') {
        const widgetToAdd = flatWidgetList.find(w => w.id === draggableId);
        onAddWidget(widgetToAdd, destinationIndex, containerId);
      } else if (
        sourceContainer === 'middle-panel' &&
        sourceContainerId === containerId &&
        sourceIndex !== destinationIndex
      ) {
        onUpdateWidgetPosition(sourceIndex, destinationIndex, containerId);
      } else if (sourceContainer === 'middle-panel' && sourceContainerId !== containerId) {
        onUpdateWidgetPanels(sourceIndex, destinationIndex, containerId, sourceContainerId);
      }
    }
  };

  const deletePagePanel = async page => {
    const updatedPanelConfig = panelConfig.filter(({ key }) => key !== page.key);
    await updatePanelConfig(updatedPanelConfig);
    setPanelWidgets(panelWidgets.filter(panel => panel.id !== page.key));
  };

  const deleteWidget = async (widget, panelIdForWidget) => {
    const panelWidgetList = panelWidgets.find(p => p.id === panelIdForWidget)?.widgets;
    const newPanelWidgets = [...panelWidgetList]
      .filter(w => (widget.id ? w.id !== widget.id : w.key !== widget.key))
      .map((w, index) => ({ ...w, priority: index + 1 }));
    await updatePanelWidget(newPanelWidgets, panelIdForWidget);
    setSelectedWidget(null);
  };

  const addChooserList = async newList => {
    try {
      const { data } = await api.post(`/lists`, newList);
      setLists([...lists, data].map(d => ({ ...d, label: d.name, value: d.id })));
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const addProducts = async (products, component) => {
    try {
      const { data } = await api.put(`/components/${component.id}/products`, products);
      const newComponent = { ...component, products: data };
      updateSelectedComponent(newComponent, true);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const onDeletePagePanel = page => {
    setInsideModal({
      type: 'delete-page-panel',
      content: {
        page,
        deletePagePanel: () => deletePagePanel(page),
      },
    });
  };

  const onDeleteWidget = (widget, panelIdForWidget) => {
    setInsideModal({
      type: 'delete-form-widget',
      content: {
        widget,
        deleteWidget: () => deleteWidget(widget, panelIdForWidget),
      },
    });
  };

  const onAddChooserList = () => {
    setInsideModal({
      type: 'add-chooser-list',
      content: {
        addChooserList: newList => addChooserList(newList),
      },
    });
  };

  const onAddProductsList = (integration, component) => {
    setInsideModal({
      type: 'add-products-list',
      content: {
        addProductList: products => addProducts(products, component),
        integration,
      },
    });
  };

  const onUpdatePanelName = (name, key) => {
    const updatedPanelConfig = panelConfig.map(p => (p.key === key ? { ...p, name } : p));
    setPanelConfig(updatedPanelConfig);
    updatePanelConfigData(updatedPanelConfig);
  };

  const onUpdateWidgetDetails = async (widget, panelIdForWidget, isNewComponent = false) => {
    const { panelId, ...restWidget } = widget;
    const panelWidgetList = panelWidgets.find(p => p.id === panelIdForWidget)?.widgets;
    const newPanelWidgets = [...panelWidgetList].map(w =>
      (restWidget.id ? w.id === restWidget.id : w.key === restWidget.key) ? { ...restWidget } : w,
    );
    if (widgetUpdateTimeout.current) {
      clearTimeout(widgetUpdateTimeout.current);
    }
    widgetUpdateTimeout.current = setTimeout(async () => {
      await updatePanelWidget(newPanelWidgets, panelIdForWidget);
    }, 0);
  };

  const onAddNewComponentInWidget = async (widget, panelIdForWidget) => {
    const { panelId, ...restWidget } = widget;
    const panelWidgetList = panelWidgets.find(p => p.id === panelIdForWidget)?.widgets;
    const newPanelWidgets = [...panelWidgetList].map(w => (w.id === restWidget.id ? { ...restWidget } : w));

    const data = await updatePanelWidget(newPanelWidgets, panelIdForWidget);
    const widgetData = data?.find(d => d.id === restWidget.id);
    if (widgetData) {
      const latestComponent = widgetData?.components?.at(-1) || selectedComponent;
      setSelectedWidget({ ...selectedWidget, components: widgetData?.components });
      setSelectedComponent({ ...latestComponent });
    }
  };

  const updateSelectedWidget = widget => {
    setSelectedComponent(null);
    setSelectedWidget(widget);
  };

  const updateComponentInSelectedPanelWidget = async (selectedWidget, newComponent) => {
    const { panelId, components, ...restWidget } = selectedWidget;

    const updatedWidget = {
      panelId,
      ...restWidget,
      components: [...components].map(c =>
        (newComponent.isNew ? c.key === newComponent.key : c.id === newComponent.id) ? { ...newComponent } : c,
      ),
    };
    const updatedPanel = panelWidgets
      .find(p => p.id === panelId)
      ?.widgets?.map(w => ((restWidget.id ? w.id === restWidget.id : w.key === restWidget.key) ? updatedWidget : w));
    const newPanelWidgets = panelWidgets.map(panel =>
      panel.id === panelId ? { ...panel, widgets: updatedPanel } : panel,
    );
    setSelectedWidget(updatedWidget);
    setPanelWidgets(newPanelWidgets);
    getIntegrationIdFromProductComponent(newPanelWidgets);
  };

  const updateComponentAPI = async (
    panelWidgetId,
    componentId,
    request,
    updateComponent = false,
    selectedWidget = {},
  ) => {
    const { data } = await api.put(`/panel_widgets/${panelWidgetId}/components/${componentId}`, request);
    if (updateComponent) {
      setSelectedComponent(data);
      updateComponentInSelectedPanelWidget(selectedWidget, data);
    }
  };

  const updateSelectedComponent = (component, updateComponent = true, widget) => {
    let newComponent = component ? { ...component } : null;
    if (newComponent?.component_type === 'PRODUCT' && selectedIntegrationInComponent?.id !== newComponent?.id) {
      newComponent = {
        ...newComponent,
        products: selectedIntegrationInComponent?.products || [],
        integration: selectedIntegrationInComponent?.integration,
      };
    }
    if (newComponent?.component_type === 'PAYMENT' && selectedIntegrationInComponent?.id !== newComponent?.id) {
      newComponent = {
        ...newComponent,
        integration: selectedIntegrationInComponent?.integration,
      };
    }
    setSelectedComponent(newComponent);
    if (updateComponent && newComponent) {
      const { panelId, components, ...restWidget } = selectedWidget || widget;

      const updatedWidget = {
        panelId,
        ...restWidget,
        components: [...components].map(c =>
          (newComponent.isNew ? c.key === newComponent.key : c.id === newComponent.id) ? { ...newComponent } : c,
        ),
      };
      const updatedPanel = panelWidgets
        .find(p => p.id === panelId)
        ?.widgets?.map(w => ((restWidget.id ? w.id === restWidget.id : w.key === restWidget.key) ? updatedWidget : w));
      const newPanelWidgets = panelWidgets.map(panel =>
        panel.id === panelId ? { ...panel, widgets: updatedPanel } : panel,
      );
      setSelectedWidget(updatedWidget);
      setPanelWidgets(newPanelWidgets);
      getIntegrationIdFromProductComponent(newPanelWidgets);
      if (componentUpdateTimeout.current) {
        clearTimeout(componentUpdateTimeout.current);
      }
      componentUpdateTimeout.current = setTimeout(async () => {
        const updateComponent = newComponent.component_type === 'PAYMENT';
        await updateComponentAPI(
          restWidget.id,
          newComponent.id,
          newComponent,
          updateComponent,
          selectedWidget || widget,
        );
      }, 0);
    }
  };
  const updateComponent = async (component, widget) => {
    if (selectedComponent?.id === component.id) setSelectedComponent(component);
    const panelId = panelWidgets.find(p => p.widgets.find(w => w.id === widget.id))?.id;
    setPanelWidgets(
      [...panelWidgets].map(p =>
        p.id === panelId
          ? {
              ...p,
              widgets: p.widgets.map(w =>
                w.id === widget.id
                  ? { ...w, components: w.components.map(c => (c.id === component.id ? component : c)) }
                  : w,
              ),
            }
          : p,
      ),
    );
    const { data } = await api.put(`/panel_widgets/${widget.id}/components/${component.id}`, component);
  };

  const updateWidget = async widget => {
    const panelId = panelWidgets.find(p => p.widgets.find(w => w.id === widget.id))?.id;
    const pwidgets = panelWidgets.map(p =>
      p.id === panelId
        ? {
            ...p,
            widgets: p.widgets.map(w => (w.id === widget.id ? widget : w)),
          }
        : p,
    );
    const request = pwidgets
      .find(p => p.id === panelId)
      .widgets.map(w => ({
        ...w,
        components: w.components.map(c => ({
          ...c,
          image: c.image ? { ...c.image, id: c.image.id ? c.image.id : c.image?.image?.media_external_id } : null,
        })),
      }));
    setPanelWidgets(
      pwidgets.map(p =>
        p.id === panelId
          ? {
              ...p,
              widgets: request,
            }
          : p,
      ),
    );
    setSelectedWidget(widget);
    const { data } = await api.put(`/panels/${panelId}/widgets`, request);
  };

  const duplicateWidget = async widget => {
    const panelId = panelWidgets.find(p => p.widgets.find(w => w.id === widget.id))?.id;
    const pwidgets = panelWidgets.map(p =>
      p.id === panelId
        ? {
            ...p,
            widgets: [
              ...p.widgets,
              {
                ...widget,
                id: null,
                x: widget.x,
                y: widget.y + widget.height,
                components: widget.components.map(c => ({ ...c, id: null })),
              },
            ],
          }
        : p,
    );
    const { data } = await api.put(`/panels/${panelId}/widgets`, pwidgets.find(p => p.id === panelId).widgets);

    setPanelWidgets(
      pwidgets.map(p =>
        p.id === panelId
          ? {
              ...p,
              widgets: data,
            }
          : p,
      ),
    );
    setSelectedWidget(widget);
  };

  const onConfirmationPageToggle = async toggle => {
    let pageListCopy = [...panelConfig];
    if (toggle) {
      pageListCopy = [
        ...pageListCopy,
        {
          name: 'Confirmation page',
          key: uuid(),
          isNew: true,
          panel_color: '#FF11EE',
          submit_onpayment: false,
          is_confirmation_panel: true,
        },
      ];
      const updatedPanels = await updatePanelConfig(pageListCopy);
      const confirmationPanel = updatedPanels.find(p => p.is_confirmation_panel);
      updateFormAttributes({
        ...formAttributes,
        confirmation_panel: { id: confirmationPanel.id, display_watermark: false },
      });
    } else {
      pageListCopy = pageListCopy.filter(page => !page.is_confirmation_panel);
      const updatedFormAttributes = { ...formAttributes, confirmation_panel: null };
      setFormAttributes(updatedFormAttributes);
      await api.put(`/versions/${formVersionId}/form_attributes`, updatedFormAttributes);
      await updatePanelConfig(pageListCopy);
    }
  };

  const onConfirmationPageWatermarkToggle = async toggle => {
    const updatedFormAttributes = {
      ...formAttributes,
      confirmation_panel: { ...formAttributes.confirmation_panel, display_watermark: toggle },
    };
    setFormAttributes(updatedFormAttributes);
    await api.put(`/versions/${formVersionId}/form_attributes`, updatedFormAttributes);
  };

  const onUpdateProduct = async (newProducts, component) => {
    await addProducts(newProducts, component);
  };

  const selectC = component => {
    setSelectedComponent(component);
    setShowEditPage(null);
  };

  const selectWidgetC = widget => {
    setSelectedWidget(widget);
    setShowEditPage(null);
  };

  return (
    <EditFormVersionWrapper className="flex-column">
      <FormBuilderProvider
        value={{
          fonts,
          formAttributes,
          setFormAttributes: updateFormAttributes,
          panelConfig,
          setPanelConfig: updatePanelConfig,
          updatePanelPosition,
          widgetGroupList,
          onPageColorChange,
          onComponentColorChange,
          setSelectedPage,
          selectedPage,
          panelWidgets,
          setSelectedWidget: updateSelectedWidget,
          selectWidget: selectWidgetC,
          selectedWidget,
          onDeletePagePanel,
          onUpdatePanelName,
          onDeleteWidget,
          onUpdateWidgetDetails,
          selectedComponent,
          setSelectedComponent: updateSelectedComponent,
          updateComponent: updateComponent,
          updateWidget: updateWidget,
          selectComponent: selectC,
          lists,
          getListDetail,
          integrations,
          getIntegrationDetails,
          onAddWidget,
          onConfirmationPageToggle,
          updateListChoiceConfig,
          onAddChooserList,
          onAddProductsList,
          onAddNewComponentInWidget,
          onUpdateProduct,
          onConfirmationPageWatermarkToggle,
          selectedIntegrationInComponent,
          setInsideModal,
          zoom: zoom,
          setZoom: setZoom,
          duplicateWidget: duplicateWidget,
          showEditPage: showEditPage,
          setShowEditPage: setShowEditPage,
        }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {loadingFormData ? (
            <div className="item flex items-center justify-center h-full">
              <img alt="loading" height={48} width={48} src={Loading} />
            </div>
          ) : (
            <>
              <HeaderPanel
                setPreviewForm={setPreviewForm}
                previewForm={previewForm}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
              />
              <div className="flex flex-1 overflow-scroll">
                <LeftPanel previewForm={previewForm} />
                {previewForm ? (
                  <FormPreview selectedDevice={selectedDevice} />
                ) : (
                  <Fragment>
                    <MiddlePanel />
                    <RightPanel />
                  </Fragment>
                )}
              </div>
            </>
          )}
        </DragDropContext>
      </FormBuilderProvider>
      <InsideModal modal={insideModal} setModal={setInsideModal} />
    </EditFormVersionWrapper>
  );
};

export default EditFormVersion;
