import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { characterLimitRangeError, validateCharacterLimit } from '../../../helpers/utils';

const InputElement = ({
  name,
  is_required,
  value,
  defaultValue = '',
  onChange = () => {},
  label,
  type,
  className = '',
  font = null,
  fontFamily,
  is_autofill = false,
  autoFillValue,
  showRequiredError,
  showRequireConfirmationError,
  sub_label = null,
  character_limit = null,
  showCharacterLimitError = false,
  zoom,
  inputFont,
  getFontFamily,
  inputFontFamily,
  alignment,
  width,
  oneLine = false,
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (type === 'TEXTAREA' && textareaRef?.current?.style) {
      textareaRef.current.style.height = '0px';
      let scrollHeight = textareaRef.current.scrollHeight;
      scrollHeight = scrollHeight > 500 ? 500 : scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
      textareaRef.current.style.maxHeight = `${scrollHeight}px`;
    }
  }, [type, value]);

  return (
    <InputElementWrapper className={className}>
      {label && (
        <div
          style={{
            marginBottom: `${(2 * zoom) / 100}px`,
            width: width,
            minHeight: `${((font.size || 14) * zoom) / 100}px`,
          }}
          className={`flex items-center ${oneLine ? 'one-line' : ''}`}>
          <span
            className={`regular-text lighter-text ${oneLine ? 'one-line' : ''}`}
            style={{
              fontSize: `${((font.size || 14) * zoom) / 100}px`,
              color: font?.color || '#6B7280',
              ...fontFamily,
            }}>
            {name}
          </span>
          {is_required && (
            <span className="required-star ml-1" style={{ fontSize: `${((font.size || 14) * zoom) / 100}px` }}>
              *
            </span>
          )}
        </div>
      )}
      {type === 'TEXTAREA' ? (
        <textarea
          className={`regular-text px-4 input-elemen textarea-element w-full ${
            ((showRequiredError && !value) || showRequireConfirmationError) && 'input-error'
          }`}
          defaultValue={defaultValue}
          value={is_autofill ? autoFillValue : value}
          disabled={is_autofill}
          placeholder={label}
          onChange={({ target: { value } }) => onChange(value)}
          ref={textareaRef}
          style={{
            fontSize: `${((inputFont?.size || 16) * zoom) / 100}px`,
            color: inputFont?.color || '#6B7280',
            ...inputFontFamily,
            ...alignment,
          }}
        />
      ) : (
        <input
          className={`regular-text px-4 input-element ${
            ((showRequiredError && !value) || showRequireConfirmationError) && 'input-error'
          }`}
          defaultValue={defaultValue}
          value={is_autofill ? autoFillValue : value}
          disabled={is_autofill}
          type={type === 'NUMERIC' ? 'number' : 'text'}
          placeholder={label}
          onChange={({ target: { value } }) => onChange(value)}
          style={{
            fontSize: `${((inputFont?.size || 16) * zoom) / 100}px`,
            color: inputFont?.color || '#6B7280',
            ...inputFontFamily,
            ...alignment,
            width: width,
          }}
        />
      )}
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
      {showCharacterLimitError && !validateCharacterLimit(character_limit, value || '') && (
        <div className="mt-1">
          <span className="regular-text error-text" style={{ fontSize: `${font.size || 14}px`, ...fontFamily }}>
            {characterLimitRangeError(character_limit)}
          </span>
        </div>
      )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  .input-element {
    height: 100%;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.border};
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;

    &:hover {
      // border: 1px solid ${({ theme }) => theme.colors.zervioBlueSecondary};
    }

    &:focus {
      // border: 1px solid ${({ theme }) => theme.colors.zervioBlue};
    }

    &::placeholder {
      opacity: 0.3;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .textarea-element {
    min-height: 40px;
    /* max-height: 500px; */
    resize: none;
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
  }

  .input-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    &:hover {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
`;

export default InputElement;
