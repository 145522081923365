import React from 'react';
import SliderWrapper from './slider.styled.js';
import ReactSlider from 'react-slider';

const Slider = ({ currentValue, setCurrentValue }) => {
  return (
    <SliderWrapper className="flex items-center">
      <ReactSlider
        className="customSlider"
        thumbClassName="customSlider-thumb"
        trackClassName="customSlider-track"
        markClassName="customSlider-mark"
        min={60}
        max={200}
        defaultValue={100}
        value={currentValue}
        onChange={value => setCurrentValue(value)}
      />
    </SliderWrapper>
  );
};

export default Slider;
