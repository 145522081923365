import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../context/FormBuilderContext';
import { RightPanelWrapper } from './edit-form-version.styled';
import { ConfirmationPageSettings } from './form-settings/confirmation-page-settings';
import { FontSettings } from './form-settings/font-settings';
import { GeneralSettings } from './form-settings/general-settings';
import { ProgressbarSettings } from './form-settings/progress-bar-settings';
import { WaterMarkSettings } from './form-settings/watermark-settings';
import { WidgetConfigPanel } from './widget-config-panel';
import { PanelImageSetting } from './form-settings/panel-image-setting';
import { PanelConfig } from './panel-config';

const RightPanel = () => {
  const {
    selectedWidget,
    selectedPage,
    panelConfig,
    setPanelConfig,
    setSelectedPage,
    showEditPage,
    setShowEditPage,
    panelWidgets,
    selectWidget,
  } = useContext(FormBuilderContext);
  const [colorPicker, setColorPicker] = useState('');
  const { t } = useTranslation();

  return (
    <RightPanelWrapper className="flex border-left pxy-4 flex-column" style={{ height: 'calc(100vh - 130px)' }}>
      {showEditPage ? (
        <div className="flex-column flex-1 overflow-hidden h-full">
          <PanelConfig
            panel={panelConfig.find(p => p.id === showEditPage?.id)}
            panelConfig={panelConfig}
            setPanelConfig={setPanelConfig}
            setShowEditPage={setShowEditPage}
            setSelectedPage={setSelectedPage}
            panelWidgets={panelWidgets}
            selectWidget={selectWidget}
          />
        </div>
      ) : selectedWidget ? (
        <div className="flex-column flex-1 overflow-hidden h-full">
          <WidgetConfigPanel />
        </div>
      ) : (
        <div className="flex-1 h-full">
          <div className="flex pb-4">
            <label className="medium-text font-18">{t('FORM_SETTINGS')}</label>
          </div>
          <div className="flex-column flex-1 overflow-scroll h-full">
            <GeneralSettings
              key={'generalSettingsComponent'}
              setColorPicker={setColorPicker}
              colorPicker={colorPicker}
            />
            <FontSettings key={'fontSettingsComponent'} setColorPicker={setColorPicker} colorPicker={colorPicker} />
            <ProgressbarSettings key={'progressBarSettings'} />
            <WaterMarkSettings key={'waterMarkSettings'} />
            <ConfirmationPageSettings key={'confirmationPageSettings'} />
            <div className="flex-column pt-6 border-top">
              <label className="flex medium-text  mb-6">{t('PANELS_UC')}</label>
              {panelConfig.map(panel => (
                <div
                  className="flex items-center px-4 py-2 radius-4 mb-4 cursor widget-component-container"
                  onClick={() => {
                    setSelectedPage(panel);
                    setShowEditPage(panel);
                  }}>
                  <label className="regular-text font-16 flex-1 one-line">{panel.name}</label>
                </div>
              ))}
              {/* <div className="add-new-component-btn">
                <Button
                  className={'flex items-center justify-center default-secondary'}
                  label={'Add new component'}
                  borderRadius={'12px'}
                  size={'medium'}
                  icon={<PlusIcon height={16} width={16} />}
                  width={'100%'}
                  onClick={() => onAddNewComponent()}
                  disabled={loadingAddComponent}
                />
              </div> */}
            </div>
            {/* <div className="flex-column border-top py-8 px-4">
            <label className="medium-text mb-4">{t('PANELS')}</label>
            {panelConfig.map(panel => (
              <PanelImageSetting
                key={panel.id}
                panel={panel}
                panelConfig={panelConfig}
                setPanelConfig={setPanelConfig}
              />
            ))}
          </div> */}
          </div>
        </div>
      )}
    </RightPanelWrapper>
  );
};

export default RightPanel;
