import React, { useContext, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import {
  duplicateFormVersion,
  exportFormVersion,
  importFormVersion,
  updateFormVersionToRetire,
} from '../store/features/formsSlice';
import { addToast } from '../store/features/toastSlice';
import CommonPopup from './common-popup';
import { getFormList } from '../store/features/formsSlice';
import { FormVersionActionsWrapper } from './modal.styled';
import DropDown from '../components/McDropdown';
import Button from '../components/Elements/button/button';

const FormExportAction = () => {
  const dispatch = useDispatch();
  const { formsList } = useSelector(state => state.forms);
  const pageRef = useRef();

  const { modal, setModal } = useContext(OrganisationContext);
  const { title, isDuplicate, isRetire, form, formVersion, onSuccess = () => {} } = modal.content;
  const [selectedForm, setSelectedForm] = useState();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const onConfirmAction = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(importFormVersion({ formId: selectedForm.id, body: data }))
      .then(() => {
        dispatch(addToast({ error: false, text: `V${formVersion?.version_no} has been imported`, id: uuid() }));
        onSuccess();
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: `V${formVersion?.version_no} has not been imported`, id: uuid() }));
      })
      .finally(() => setModal(initModal));
  };

  useEffect(() => {
    dispatch(exportFormVersion({ formId: form.id, versionId: formVersion.id })).then(d => setData(d));
  }, []);

  useEffect(() => {
    fetchForms(0, false);
  }, []);

  const fetchForms = (page, merge) => {
    dispatch(getFormList({ params: { page: page }, url: '' }));
  };

  const defaultProps = {
    modalClassName: '',
    onConfirm: () => {},
    onCancel: () => {},
    disabled: false,
    cancelButtonProps: {
      label: 'Copy',
      size: 'small',
      borderRadius: '8px',
      className: 'primary',
      width: '100px',
    },
    popupTitle: '',
    popupSubTitle: '',
  };

  const cancelButtonStyleProps = { ...defaultProps.cancelButtonProps };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isDuplicate ? 'Duplicate' : isRetire ? 'Yes, retire!' : 'Confirm',
        className: isRetire ? 'negative' : 'primary',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <FormVersionActionsWrapper className="flex-column items-start w-full pxy-4 radius-4 mt-6">
        <div className="flex items-start w-full">
          <div className="flex-column items- start flex-1">
            <div className="flex items-center justify-between">
              <label className="semibold-text font-16 fw-600">
                {form?.name} <span className="bold-text font-16 ml-2 zen-purple-text">V{formVersion?.version_no}</span>
              </label>
              <div className="flex">
                <Button
                  {...cancelButtonStyleProps}
                  onClick={() => navigator.clipboard.writeText(JSON.stringify(data))}
                />
              </div>
            </div>
          </div>
          {form?.prizedraw?.image && (
            <div className="flex items-center h-full">
              <img className="form-image" src={form?.prizedraw?.image?.url} alt="form-image" />
            </div>
          )}
        </div>
        <div className="normal-text grey-text mt-4">{form?.description}</div>
      </FormVersionActionsWrapper>
      <div className="flex flex-1 mt-4 w-full">
        <DropDown
          options={formsList?.content}
          selected={selectedForm}
          setSelected={setSelectedForm}
          placeholder={'Select form'}
          hasMore={formsList?.hasMore}
          isPaged={true}
          fetchData={() => fetchForms(pageRef.current + 1, true)}
        />
      </div>
    </CommonPopup>
  );
};

export default FormExportAction;
