import React, { Fragment, useCallback, useState, useEffect } from 'react';

import { useDrag, useDrop } from 'react-dnd';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import WidgetComponent from './widget-component';

import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as DragHandleIcon } from '../../assets/images/drag-handle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/bottom-right.svg';

const Widget = ({
  widget,
  index,
  panelId,
  selectedWidget,
  setSelectedWidget,
  groupByRow,
  showRequiredError,
  formAttributes,
  getFontFamily,
  autoFillLookUp,
  values,
  id,
  left,
  top,
  hideSourceOnDrag,
  onDeleteWidget,
  selectComponent,
  setSelectedComponent,
  selectWidget,
  updateComponent,
  panelWidgets,
  selectedComponent,
  updateWidget,
  selectedPage,
  duplicateWidget,
  zoom,
  showWidgetBorders,
  setShowWidgetBorders,
  panelWidth,
}) => {
  const style = {
    position: 'absolute',
    // border: '1px dashed gray',
    cursor: 'move',
  };
  const [size, setSize] = useState({ width: widget.width, height: widget.height });
  const [resize, setResize] = useState(false);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'box',
      item: { id, left, top },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top],
  );
  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }
  const moveBox = useCallback(
    (component, widget, left, top) => {
      updateComponent({ ...component, x: left, y: top }, { ...widget, panelId });
    },
    [panelWidgets],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'component',
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.component, item.widget, left, top);
        return undefined;
      },
    }),
    [moveBox],
  );
  const [drag_, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const startResize = e => {
    setResize(true);
    setDrag({
      active: true,
      x: e.clientX,
      y: e.clientY,
    });
  };
  const resizeFrame = e => {
    if (!resize) return;
    const { active, x, y } = drag_;
    if (active) {
      const xDiff = Math.abs(x - e.clientX);
      const yDiff = Math.abs(y - e.clientY);
      const newW = x > e.clientX ? size.width - xDiff : size.width + xDiff;
      const newH = y > e.clientY ? size.height - yDiff : size.height + yDiff;

      setDrag({ ...drag_, x: e.clientX, y: e.clientY });
      setSize({ width: newW, height: newH });
    }
  };

  const stopResize = e => {
    if (!resize) return;
    setResize(false);
    setDrag({ ...drag_, active: false });
    const ww = panelWidgets
      .map(pw => pw.widgets.map(w => ({ ...w, panelId: pw.id })))
      .flat()
      .find(w => w.id === id);
    updateWidget({ ...ww, width: size.width, height: size.height });
  };

  useEffect(() => {
    setSize({ width: widget.width, height: widget.height });
  }, [widget]);
  // relative ${selectedWidget?.id === widget.id ? 'selected-widget' : ' p-widget'}
  return (
    <div
      style={{
        ...style,
        // left: left * (zoom / 100),
        // top: top * (zoom / 100),
        // zIndex: isDragging ? 2 : 1,
        width: '100%',
        height: '100%',
      }}
      // ref={resize ? null : drag}
      // data-testid={resize ? null : 'box'}
      className={`widget-hover flex `}
      // onClick={e => {
      //   e.stopPropagation();
      //   selectWidget({ ...widget, panelId });
      // }}
      // onMouseDown={e => {
      //   e.stopPropagation();
      //   selectWidget({ ...widget, panelId });
      //   selectComponent(null);
      // }}
      // onMouseMove={resizeFrame}
      // onMouseUp={stopResize}
      // onMouseEnter={() => setShowWidgetBorders(true)}
      // onMouseLeave={() => setShowWidgetBorders(false)}
    >
      <div
        className={`flex-column flex-1 put here `}
        ref={drop}
        style={
          {
            // width: `${size?.width * (zoom / 100)}px`,
            // minWidth: `${size?.width * (zoom / 100)}px`,
            // maxWidth: `${size?.width * (zoom / 100)}px`,
            // height: `${size?.height * (zoom / 100)}px`,
            // minHeight: `${size?.height * (zoom / 100)}px`,
            // maxHeight: `${size?.height * (zoom / 100)}px`,
            // borderRadius: `${(16 * zoom) / 100}px`,
            // border: showWidgetBorders ? '1px dashed rgb(36, 150, 255)' : '',
          }
        }>
        {widget.components.map(
          component =>
            component.is_visible && (
              <WidgetComponent
                id={component.id}
                left={component.x || 0}
                top={component.y || 0}
                component={component}
                index={index}
                showRequiredError={showRequiredError}
                formAttributes={formAttributes}
                getFontFamily={getFontFamily}
                autoFillLookUp={autoFillLookUp}
                values={values}
                selectComponent={selectComponent}
                panelId={panelId}
                widget={widget}
                setSelectedWidget={setSelectedWidget}
                selectedComponent={selectedComponent}
                updateWidget={updateWidget}
                selectedPage={selectedPage}
                resize={resize}
                setResize={setResize}
                updateComponent={updateComponent}
                zoom={zoom}
                width={size.width}
                height={size.height}
              />
            ),
        )}
        {/* <div
          className="absolute flex items-center justify-center resize-icon"
          style={{ right: 0, bottom: 0, zIndex: 3, width: '20px', height: '20px', cursor: 'se-resize' }}
          onMouseDown={startResize}
          onClick={e => e.stopPropagation()}>
          <ArrowRightIcon width="10px" height="10px" />
        </div> */}
      </div>

      {/* <div className="flex-column items-center justify-center mt-4  widget-hover-icon">
        <div
          className="pxy-1 flex items-center justify-center radius-1 delete-widget cursor mb-2"
          onClick={e => {
            e.stopPropagation();
            onDeleteWidget(widget, panelId);
          }}>
          <DeleteIcon />
        </div>
        <div className="flex items-center justify-center relative add-widget my-1">
          <div className="flex items-center justify-center">
            <PlusIcon className="cursor" onClick={() => duplicateWidget({ ...widget, panelId })} />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Widget;
