import styled from 'styled-components';

export const InviteWrapper = styled.div`
  box-shadow: 0px 6px 20px rgba(103, 103, 103, 0.1);
  padding: 64px 0;
  width: 500px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const AccountActivationWrapper = styled.div`
  box-shadow: 0px 6px 20px rgba(103, 103, 103, 0.1);
  padding: 56px 64px 64px 64px;
  background-color: ${({ theme }) => theme.colors.white};

  .consent-wrapper {
    width: 360px;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }

  .input {
    outline: 0;
    font-size: 16px;
    height: 48px;
    background: #ffffff;
    width: 360px;
    padding: 0 16px;
    font-family: Poppins Regular;
    font-size: 16px;
    color: #020819;
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};
    border-radius: 16px;
    &:focus {
      border-color: ${({ theme }) => theme.colors.zervioBlue};
    }
    &::placeholder {
      opacity: 0.3;
    }
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .phone-input-error {
    .input {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }

  .password-requirement-tooltip {
    position: relative;
    .password-requirement-tooltip-details {
      position: relative;
    }
    .error-icon {
      path {
        fill: #ff406e;
      }
    }
    .tooltip-content {
      height: fit-content;
      width: fit-content;
      max-width: fit-content;
      z-index: 10;
      margin: 4px 0;
      box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.14);
      padding: 12px 16px;

      &::before {
        width: 0px;
        height: 0px;
      }

      #arrow,
      #arrow::before {
        position: absolute;
        width: 16px;
        height: 16px;
        background: inherit;
        background: #ffffff;
      }

      #arrow {
        visibility: hidden;
      }

      #arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
      }
    }

    .tooltip-content[data-placement^='right'] > #arrow {
      left: -6px;
    }
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }

  .error-input {
    border: 1px solid ${({ theme }) => theme.colors.ZenNegative};

    &:focus {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }

  .error-phone {
    .phone-input {
      border: 1px solid ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .password-error {
    width: 100%;
    background: #ff406e;
    border-radius: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;
