import uuid from 'react-uuid';

export const initModal = {
  type: null,
  content: {},
};

export const enumCountryCode = {
  USA: '+1',
  // ENG: '+44',
};

export const enumAddressType = {
  postal: 'POSTAL',
  office: 'OFFICE',
  operating: 'OPERATING',
  accounting: 'ACCOUNTING',
};

export const widgetNewComponentDetails = {
  key: uuid(),
  name: 'New component',
  priority: 0,
  component_type: 'ALPHANUMERIC',
  lexicon: null,
  label: null,
  mask: null,
  default_value: null,
  column: 1,
  row: 1,
  image: null,
  range: {
    min: null,
    max: null,
  },
  is_required: false,
  is_visible: true,
  integration: {
    id: null,
    name: null,
    public_key: null,
  },
  requires_confirmation: false,
  font: null,
  is_confidential: false,
  list: null,
  sub_label: null,
  component_layout: null,
  is_autofill: false,
  vendor: {
    id: null,
    name: null,
  },
  alignment: null,
  component_color: null,
  flows: [],
  products: [],
  character_limit: {
    min: null,
    max: null,
  },
  width: 150,
  height: 60,
  x: 0,
  y: 0,
};
