import update from 'immutability-helper';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import uuid from 'react-uuid';
import { ReactComponent as AddPageIcon } from '../../assets/images/add-page.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as LockIcon } from '../../assets/images/lock.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash-red.svg';
import Button from '../../components/Elements/button/button';
import Address from '../../components/FormElements/address';
import Checkbox from '../../components/FormElements/checkbox';
import DateOfBirthDayMonthYear from '../../components/FormElements/date-of-birth/DayMonthYear';
import DateSelectorElement from '../../components/FormElements/date-selector';
import Dropdown from '../../components/FormElements/dropdown';
import FormPhoneInput from '../../components/FormElements/form-phone-input';
import HeaderElement from '../../components/FormElements/header';
import ImageElement from '../../components/FormElements/image-element';
import InputElement from '../../components/FormElements/input';
import Payment from '../../components/FormElements/payment';
import Signature from '../../components/FormElements/signature';
import UpdateAction from '../../components/common/update-action/index';
import { DndContainer } from '../../components/dnd';
import { DndCard } from '../../components/dnd/card';
import { Tooltip } from '../../components/tooltip/tooltip';
import { widgetNewComponentDetails } from '../../constant/InitialData';
import FormBuilderContext from '../../context/FormBuilderContext';
import { getAlignment, getFontFamily } from '../../helpers/utils';
import { MiddlePanelWrapper } from './edit-form-version.styled';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Widget from './widget';

const PagePanel = () => {
  const {
    panelConfig,
    setPanelConfig,
    updatePanelPosition,
    selectedPage,
    setSelectedPage,
    onDeletePagePanel,
    onUpdatePanelName,
    setShowEditPage,
  } = useContext(FormBuilderContext);
  const [showAddPage, setShowAddPage] = useState({ step: null, position: null, pageName: null });
  const [showEditPage, setShowEditPageo] = useState();
  const onAddNewPage = () => {
    const pageIndex = panelConfig.findIndex(({ key }) => key === showAddPage.step);
    const pageListCopy = [...panelConfig];
    if (showAddPage.position === 'PREVIOUS') {
      pageListCopy.splice(pageIndex, 0, {
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    } else if (showAddPage.position === 'NEXT') {
      pageListCopy.splice(pageIndex + 1, 0, {
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    } else if (showAddPage.step === 'FIRST') {
      pageListCopy.push({
        name: showAddPage.pageName || '',
        key: uuid(),
        isNew: true,
        panel_color: '#FF11EE',
        submit_onpayment: false,
        is_confirmation_panel: false,
      });
    }
    setPanelConfig([...pageListCopy]);
    setShowAddPage({ step: null, position: null, pageName: null });
  };

  const newPageToolTipContent = () => {
    return (
      <div className="add-page-wrapper">
        <div>
          <label className="flex regular-text grey-text font-12 mb-1">New page</label>
          <input
            className="input w-full add-page-input"
            placeholder="Page name"
            value={selectedPage?.pageName}
            onChange={({ target: { value } }) => setShowAddPage({ ...showAddPage, pageName: value })}
          />
        </div>
        <div className="flex col-gap-6 mt-6">
          <Button
            size="medium"
            label="Cancel"
            className="primary-white flex-1"
            onClick={() => setShowAddPage({ step: null, position: null, pageName: null })}
          />
          <Button size="medium" label="Add" className="primary flex-1" onClick={() => onAddNewPage()} />
        </div>
      </div>
    );
  };

  const onDeletePanel = key => {
    onDeletePagePanel(panelConfig.find(panel => panel.key === key));
  };

  const updatePanelName = () => {
    const { name, key, previousName } = showEditPage;
    onUpdatePanelName(name || previousName, key);
    setShowEditPageo(null);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const panels = reorder(panelConfig, result.source.index, result.destination.index);
    updatePanelPosition(panels);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    background: isDragging ? 'rgb(239, 246, 255)' : '',
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    ...draggableStyle,
    width: 'fit-content',
  });

  return (
    <div className="w-full flex overflow-scroll steps-list">
      {panelConfig.length === 0 && (
        <div className="flex step-container">
          <div className="flex items-center px-3 add-step">
            <Tooltip
              shouldOpen={false}
              isAlwaysOpen={showAddPage.step === 'FIRST'}
              content={newPageToolTipContent()}
              placement={'bottom-start'}>
              <AddPageIcon className="cursor" onClick={() => setShowAddPage({ step: 'FIRST' })} />
            </Tooltip>
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div className="flex" ref={provided.innerRef} {...provided.droppableProps}>
              {panelConfig.map((panel, index) => (
                <Draggable key={panel.id} draggableId={panel.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      <div className="flex step-container">
                        <div
                          className={`flex items-center px-3 add-step ${
                            showAddPage.step !== panel.key && showEditPage?.key !== panel.key && 'display-none'
                          }`}>
                          <Tooltip
                            shouldOpen={false}
                            isAlwaysOpen={showAddPage.step === panel.key && showAddPage.position === 'PREVIOUS'}
                            content={newPageToolTipContent()}
                            placement={'bottom-start'}>
                            <AddPageIcon
                              className="cursor"
                              onClick={() => setShowAddPage({ step: panel.key, position: 'PREVIOUS' })}
                            />
                          </Tooltip>
                        </div>
                        <div
                          onClick={() => {
                            setSelectedPage(panel);
                            setShowEditPage(panel);
                          }}
                          className={`flex items-center px-2 step-wrapper cursor ${
                            index === 0 ? 'first-page' : index === panelConfig.length - 1 ? 'last-page' : 'middle-page'
                          } ${selectedPage.key === panel.key && 'selected-step'}`}>
                          {showEditPage?.key === panel.key ? (
                            <div className="page-edit-action-tooltip">
                              <Tooltip
                                isAlwaysOpen={true}
                                content={
                                  <div className="page-edit-action">
                                    <UpdateAction
                                      onCancel={e => {
                                        e.stopPropagation();
                                        setShowEditPageo(null);
                                      }}
                                      onUpdate={e => {
                                        e.stopPropagation();
                                        updatePanelName();
                                      }}
                                    />
                                  </div>
                                }
                                placement="bottom"
                                className="w-full">
                                <input
                                  className="input page-edit-input"
                                  placeholder="Page name"
                                  value={showEditPage.name}
                                  autoFocus
                                  onChange={({ target: { value } }) =>
                                    setShowEditPageo({ ...showEditPage, name: value })
                                  }
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <>
                              <label
                                className={`flex regular-text font-12 pl-2 flex-1 one-line ${
                                  selectedPage.key === panel.key && 'medium-text zen-purple-text'
                                }`}>
                                {panel.name}
                                {!panel.is_confirmation_panel && (
                                  <div
                                    className="display-none delete-step ml-1"
                                    onClick={e => {
                                      e.stopPropagation();
                                      setShowEditPageo({ name: panel.name, key: panel.key, previousName: name });
                                    }}>
                                    <EditIcon />
                                  </div>
                                )}
                              </label>
                              {panel.is_confirmation_panel ? (
                                <div className="display-none delete-step">
                                  <LockIcon height={16} width={16} />
                                </div>
                              ) : (
                                <div className="display-none delete-step">
                                  <DeleteIcon
                                    height={16}
                                    width={16}
                                    onClick={e => {
                                      e.stopPropagation();
                                      onDeletePanel(panel.key);
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        {!panel.is_confirmation_panel && (
                          <div
                            className={`flex items-center px-3 add-step ${
                              showAddPage.step !== panel.key && showEditPage?.key !== panel.key && 'display-none'
                            }`}>
                            <Tooltip
                              shouldOpen={false}
                              isAlwaysOpen={showAddPage.step === panel.key && showAddPage.position === 'NEXT'}
                              content={newPageToolTipContent()}
                              placement={'bottom-start'}>
                              <AddPageIcon
                                className="cursor"
                                onClick={() => setShowAddPage({ step: panel.key, position: 'NEXT' })}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>{' '}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const WidgetPanel = () => {
  const {
    formAttributes,
    panelWidgets,
    selectedWidget,
    setSelectedWidget = () => {},
    selectWidget,
    onDeleteWidget,
    panelConfig,
    onAddWidget,
    zoom,
    onUpdateWidgetDetails,
    selectComponent,
    setSelectedComponent,
    updateComponent,
    updateWidget,
    selectedPage,
    selectedComponent,
    duplicateWidget,
    setShowEditPage,
  } = useContext(FormBuilderContext);
  const { display_watermark, alignment, watermark_location, image } = formAttributes?.watermark || {};
  const { display_watermark: confirmation_panel_watermark } = formAttributes?.confirmation_panel || {};

  const [values, setValues] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const showRequiredError = false;
  const showRequireConfirmationError = false;
  const [showWidgetBorders, setShowWidgetBorders] = useState(false);

  const onAddressUpdate = (address, widget) => {
    const addressValue = widget.components
      .filter(c => c.lexicon?.includes('contact'))
      .reduce((acc, { lexicon, id }) => {
        acc[id] = address[lexicon.split('.')[1]] || '';
        return acc;
      }, {});
    setSelectedAddress(address);
    return addressValue;
  };

  const autoFillLookUp = lexicon => {
    return '';
  };

  const getFormAttributeValue = field => {
    return null;
  };

  const getProductOptions = () => {
    return [];
  };

  const selectedProduct = {};

  const setPaymentDetails = () => {};
  const onSubmitForm = () => {};

  const groupByRow = components => {
    return components.reduce((acc, curr) => ({ ...acc, [curr.row]: (acc[curr.row] || []).concat(curr) }), {});
  };

  const getPanelWidgetList = panel => {
    return panelWidgets.find(p => (panel.key ? panel.key === p.id : panel.id === p.id))?.widgets || [];
  };

  const onAddNewWidget = (panelId, newWidgetIndex) => {
    onAddWidget(
      {
        name: 'New widget',
        key: uuid(),
        isNew: true,
        components: [widgetNewComponentDetails],
        widget_type: 'BASIC',
      },
      newWidgetIndex,
      panelId,
      true,
    );
  };

  const renderWatermark = (panel, panelIndex, panelConfig) => {
    const panelConfigWithoutConfirmationPanel = panelConfig.filter(p => !p.is_confirmation_panel);
    if (panel.is_confirmation_panel && !confirmation_panel_watermark) {
      return;
    }
    if (watermark_location === 'FIRSTPAGE' && panelIndex !== 0 && !panel.is_confirmation_panel) {
      return;
    }
    if (
      watermark_location === 'LASTPAGE' &&
      panelConfigWithoutConfirmationPanel.length - 1 !== panelIndex &&
      !panel.is_confirmation_panel
    ) {
      return;
    }
    if (!image?.image?.url) {
      return;
    }
    return (
      <div
        className={`flex mt-4 ${
          alignment === 'LEFT'
            ? 'items-start justify-start'
            : alignment === 'RIGHT'
            ? 'items-start justify-end'
            : 'items-end justify-center'
        }`}>
        <img height={64} width={192} src={image?.image?.url} alt="watermark" className="watermark" />
      </div>
    );
  };
  const moveBox = useCallback(
    (id, left, top) => {
      const widget = panelWidgets
        .map(pw => pw.widgets.map(w => ({ ...w, panelId: pw.id })))
        .flat()
        .find(w => w.id === id);
      updateWidget({ ...widget, x: left, y: top });
    },
    [panelWidgets],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'box',
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox],
  );
  if (!selectedPage) return <div></div>;
  return (
    <div className="flex-column items-center h-auto flex-1 form-content-wrapper" ref={drop}>
      <Fragment>
        <Droppable droppableId={`middle-panel:${selectedPage.key || selectedPage.id}`}>
          {provided => (
            <div
              id={`middle-panel:${selectedPage.key || selectedPage.id}`}
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`flex-column form-content flex-1 mb-10 relative`}
              style={{
                backgroundColor: formAttributes.color.page,
                minWidth: `${formAttributes.panel_width * (zoom / 100)}px`,
                maxWidth: `${formAttributes.panel_width * (zoom / 100)}px`,
                minHeight: `${formAttributes.panel_height * (zoom / 100)}px`,
                height: `${formAttributes.panel_height * (zoom / 100)}px`,
                maxHeight: `${formAttributes.panel_height * (zoom / 100)}px`,
                // borderRadius: `${(24 * zoom) / 100}px`,
                backgroundImage: `url(${
                  selectedPage.image ? selectedPage.image?.url : formAttributes.background?.image?.image?.url
                })`,
                backgroundSize: 'cover',
              }}
              onClick={() => {
                setSelectedWidget(null);
                setSelectedComponent(null);
                setShowEditPage(selectedPage);
              }}>
              {getPanelWidgetList(selectedPage)?.map((widget, index) => (
                <Widget
                  key={widget.id}
                  id={widget.id}
                  left={widget.x || 0}
                  top={widget.y || 0}
                  widget={widget}
                  index={index}
                  panelId={selectedPage.key || selectedPage.id}
                  selectedWidget={selectedWidget}
                  setSelectedWidget={setSelectedWidget}
                  groupByRow={groupByRow}
                  showRequiredError={showRequiredError}
                  formAttributes={formAttributes}
                  getFontFamily={getFontFamily}
                  autoFillLookUp={autoFillLookUp}
                  values={values}
                  onDeleteWidget={onDeleteWidget}
                  selectComponent={selectComponent}
                  setSelectedComponent={setSelectedComponent}
                  selectWidget={selectWidget}
                  updateComponent={updateComponent}
                  panelWidgets={panelWidgets}
                  selectedComponent={selectedComponent}
                  updateWidget={updateWidget}
                  selectedPage={selectedPage}
                  duplicateWidget={duplicateWidget}
                  zoom={zoom}
                  showWidgetBorders={showWidgetBorders}
                  setShowWidgetBorders={setShowWidgetBorders}
                  panelWidth={formAttributes.panel_width}
                />
              ))}
              {provided.placeholder}
              {display_watermark && renderWatermark(panel, index, panelConfig)}
            </div>
          )}
        </Droppable>
      </Fragment>
    </div>
  );
};

const MiddlePanel = () => {
  const { formAttributes } = useContext(FormBuilderContext);

  return (
    <MiddlePanelWrapper
      className="flex-column flex-1 overflow-scroll"
      backgroundColor={formAttributes.background.color}>
      {' '}
      <PagePanel />
      <div className="flex-column flex-1 overflow-scroll">
        <DndProvider backend={HTML5Backend}>
          <WidgetPanel />
        </DndProvider>
      </div>
    </MiddlePanelWrapper>
  );
};

export default MiddlePanel;
