import React, { useContext, Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormBuilderContext from '../../../context/FormBuilderContext';
import { ColorPicker } from '../common/color-picker';
import Button from '../../../components/Elements/button/button';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/features/toastSlice';
import { uploadMedia } from '../../../store/services/media';
import Slider from '../../../components/Elements/slider/slider';
import { PanelImageSetting } from './panel-image-setting';

export const GeneralSettings = ({ colorPicker, setColorPicker }) => {
  const {
    formAttributes,
    setFormAttributes,
    onPageColorChange,
    onComponentColorChange,
    panelConfig,
    setPanelConfig,
    zoom,
    setZoom,
  } = useContext(FormBuilderContext);
  const { background, color, panel_width, panel_height, field_accent_color } = formAttributes || {};
  const { button, page, default_component } = color;
  const { t } = useTranslation();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const [uploadingImage, setUploadingImage] = useState(false);

  const onImageUpload = async file => {
    if (file) {
      try {
        setUploadingImage(true);
        const imageData = await uploadMedia(file);
        setFormAttributes({
          ...formAttributes,
          background: {
            ...formAttributes.background,
            image_id: imageData?.media_external_id,
            image: {
              image: { url: imageData.url, id: imageData?.media_external_id },
              name: file.name,
              size: file.size,
            },
          },
        });
        setUploadingImage(false);
        inputRef.current.value = '';
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image' }));
        setUploadingImage(false);
        inputRef.current.value = '';
      }
    }
  };

  const onImagePanelUpload = async (file, panel) => {
    if (file) {
      try {
        setUploadingImage(true);
        const imageData = await uploadMedia(file);
        setPanelConfig(
          panelConfig.map(pc =>
            pc.id === panel.id
              ? {
                  ...pc,
                  imageId: imageData?.media_external_id,
                  image: { url: imageData.url, id: imageData?.media_external_id },
                }
              : pc,
          ),
        );
        setUploadingImage(false);
        inputRef.current.value = '';
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Error while uploading image' }));
        setUploadingImage(false);
        inputRef.current.value = '';
      }
    }
  };
  return (
    <div className="pb-8 pt-2 border-bottom flex-column">
      <label className="medium-text">{t('GENERAL_UC')}</label>
      <div className="flex items-center mt-4">
        <label className="flex flex-1 regular-text mb-1">{t('ZOOM')}</label>
        <div className="flex-1 flex items-center justify-end">
          <Slider currentValue={zoom} setCurrentValue={value => setZoom(value)} />
        </div>
      </div>
      <div className="flex items-center mt-4">
        <label className="flex flex-1 regular-text mb-1">{t('WIDTH')}</label>
        <div className="flex-1 flex items-center">
          <input
            placeholder="Height"
            className="input general-settings-input ml-4"
            type="number"
            value={panel_width}
            onChange={({ target: { value } }) => setFormAttributes({ ...formAttributes, panel_width: value })}
          />
        </div>
      </div>
      <div className="flex items-center mt-4">
        <label className="flex flex-1 regular-text mb-1">{t('HEIGHT')}</label>
        <div className="flex-1 flex items-center">
          <input
            placeholder="Height"
            className="input general-settings-input ml-4"
            type="number"
            value={panel_height}
            onChange={({ target: { value } }) => setFormAttributes({ ...formAttributes, panel_height: value })}
          />
        </div>
      </div>
      <div className="flex-column justify-center mt-4">
        <label className="flex regular-text font-12 flex-1 mb-2">Background image</label>
        {background?.image?.image?.url ? (
          <div className="flex">
            <div className="flex-column flex-1">
              {background?.image.name && <label className="regular-text">{background?.image.name}</label>}
            </div>
            <div
              className="pxy-1 radius-2 flex delete-widget h-fit-content cursor"
              onClick={() =>
                setFormAttributes({
                  ...formAttributes,
                  background: { ...formAttributes.background, image: null },
                })
              }>
              <DeleteIcon width={14} height={14} />
            </div>
          </div>
        ) : (
          <div className="flex items-center mt-2 col-gap-4">
            <input
              className="display-none"
              type="file"
              ref={inputRef}
              onChange={e => {
                const file = e.target.files[0];
                onImageUpload(file);
              }}
            />
            <Button
              icon={uploadingImage ? <LoadingIcon height={14} /> : <Fragment />}
              label={'Upload image'}
              size="medium"
              borderRadius="12px"
              className="primary-white flex-1"
              onClick={() => !uploadingImage && inputRef.current.click()}
            />
            <label className="flex-1 regular-text font-12">SVG, PNG, JPG or GIF (max. 000x000px)</label>
          </div>
        )}
      </div>
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Background color'}
        settingKey={'color'}
        selectedColor={background.color}
        onChangeColor={(settingKey, color) =>
          setFormAttributes({ ...formAttributes, background: { ...formAttributes.background, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Page color'}
        settingKey={'page'}
        selectedColor={page}
        onChangeColor={(settingKey, color) =>
          onPageColorChange({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Default component color'}
        settingKey={'default_component'}
        selectedColor={default_component}
        onChangeColor={(settingKey, color) =>
          onComponentColorChange({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Button color'}
        settingKey={'button'}
        selectedColor={button}
        onChangeColor={(settingKey, color) =>
          setFormAttributes({ ...formAttributes, color: { ...formAttributes.color, [settingKey]: color } })
        }
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
      <ColorPicker
        className="flex items-centers mt-4"
        label={'Field accent color'}
        settingKey={'field_accent_color'}
        selectedColor={field_accent_color}
        onChangeColor={(settingKey, color) => setFormAttributes({ ...formAttributes, [settingKey]: color })}
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
      />
    </div>
  );
};
