import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import { PanelImageSetting } from './form-settings/panel-image-setting';
import Switch from '../../components/common/switch';

import styled from 'styled-components';

export const PanelConfig = ({ panel, setShowEditPage, panelConfig, setPanelConfig, panelWidgets, selectWidget }) => {
  const { t } = useTranslation();

  const getPanelWidgetList = panel => {
    return panelWidgets.find(p => (panel.key ? panel.key === p.id : panel.id === p.id))?.widgets || [];
  };

  return (
    <PanelConfigWrapper className="flex-column flex-1 overflow-hidden">
      <div className="flex items-center">
        <div className="flex items-center pxy-1 mr-2 cursor" onClick={() => setShowEditPage(false)}>
          <ArrowLeftIcon height={16} width={16} />
        </div>
        <label className="flex-1 medium-text font-16">{panel.name}</label>
      </div>
      <div className="flex-column overflow-scroll px-1" style={{ height: 'calc(100vh - 120px)' }}>
        <div className="flex-column my-8">
          <label className=" flex-1 medium-text font-16">{t('OPTIONS_UC')}</label>
          <div className="mt-6">
            <PanelImageSetting key={panel.id} panel={panel} panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
          </div>
          <div className="flex mt-6">
            <label className="regular-text flex-1">{t('REPEATING')}</label>
            <Switch
              enabled={panel.is_repeating}
              onClick={() =>
                setPanelConfig(
                  panelConfig.map(pc => (pc.id === panel.id ? { ...pc, is_repeating: !panel.is_repeating } : pc)),
                )
              }
            />
          </div>
        </div>
        <div className="flex-column pt-6 border-top">
          <label className="flex medium-text  mb-6">{t('WIDGETS_UC')}</label>
          {getPanelWidgetList(panel).map(widget => (
            <div
              className="flex items-center px-4 py-2 radius-4 mb-4 cursor widget-component-container"
              onClick={() => {
                selectWidget(widget);
              }}>
              <label className="regular-text font-16 flex-1 one-line">{widget.name}</label>
            </div>
          ))}
        </div>
      </div>
    </PanelConfigWrapper>
  );
};

const PanelConfigWrapper = styled('div')`
  .input-element {
    height: 40px !important;
    border-radius: 16px;
  }
`;
