import React from 'react';
import styled from 'styled-components';
import EmptyImage from '../../../assets/images/subtract.svg';

const ImageElement = ({ src, alt = 'image', height, width, zoom }) => {
  return (
    <ImageWrapper className="flex items-center justify-center">
      {src ? (
        <img
          className={`${!width && 'w-full'}`}
          src={src}
          alt={alt}
          width={(width * zoom) / 100}
          height={(height * zoom) / 100}
        />
      ) : (
        <div className="flex flex-1 items-center justify-center empty-img-content">
          <img src={EmptyImage} className="empty-img" width={(width * zoom) / 100} height={(height * zoom) / 100} />
        </div>
      )}
    </ImageWrapper>
  );
};

const ImageWrapper = styled('div')`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  .empty-img-content {
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.backgroundColor};
    .empty-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export default ImageElement;
