import React, { useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import { OrganisationContext } from '../context/organisationContext';
import AddForm from './add-form';
import EditFormVersion from './edit-form-version';
import FormVersionAction from './form-version-action';
import { ModalWrapper } from './modal.styled';
import PublishFormVersion from './publish-form-version';
import FormExportAction from './form-export-action';
import FormImportAction from './form-import-action';

const initModal = { type: null, content: {} };

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    switch (modal.type) {
      case 'form-version-action':
        return <FormVersionAction />;
      case 'publish-form-version':
        return <PublishFormVersion />;
      case 'add-form':
        return <AddForm />;
      case 'edit-form-version':
        return <EditFormVersion />;
      case 'form-export':
        return <FormExportAction />;
      case 'form-import':
        return <FormImportAction />;
      default:
        return <></>;
    }
  };

  if (!modal.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center', overflow: 'scroll' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              marginTop: modal.content?.top ? '86px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
